<template>
  <Error
    v-if="error.show"
    :title="error.title"
    :body="error.body"
    link="link-redirect"
    image="`<img class='cohete-espacio' id='cohete' src='/img/errors/no_autorizado.svg'>`"
    vector="/img/errors/vector_158.svg"
    vamos="permiso-volver"
    header="true"
    :lang="lang"
  />
  <img
    v-else-if="loading"
    class="loading-itinerary"
    :src="require('@/assets/img/general/carga.gif')"
  />
  <div v-else>
    <right-sidebar
      @showView="showView($event)"
      :buttons="editing ? buttonsEditing : buttons"
      :view="view"
      :project_id="this.$route.params.project_id"
    />
    <confirmation-modal
      v-if="confirmationModal"
      @closeConfirmation="showConfirmationModal"
      @confirm="confirmationConfirm"
      :lang="lang"
      :body="bodyMsg"
      :warning="warning ? langFilter('warningTxt') : null"
      :fixed="true"
      :info="info"
    />
    <div class="itinerary-logo">
      <img
        :src="require(`@/assets/img/newitinerary/logo.svg`)"
        alt="Itinerary"
      />
      {{ langFilter("projects") }}
    </div>
    <header-itinerary
      @setEditing="setEditing()"
      @selectProject="selectProject($event)"
      @saveItinerary="saveItinerary($event)"
      @changeProjectName="changeProjectName($event)"
      @showConfirmationModalInfo="showConfirmationModalInfo($event)"
      :lang="lang"
      :space="space"
      :editing="editing"
      :project="project"
      :view="view"
      :percentage="percentage"
    />
    <itinerary-data
      v-if="view !== 1 && itinerary"
      @updateItinerary="updateItinerary($event)"
      @showConfirmationModal="showConfirmationModal($event)"
      @hideResources="showResources = false"
      @showResources="showResources = true"
      @updateResources="updateResources($event)"
      @toDeleteResources="toDeleteResources = $event"
      @updateResourcesItinerary="updateResourcesItinerary()"
      :lang="lang"
      :editing="editing"
      :itinerary="itinerary"
      :editingCount="editingCount"
      :project_id="project_id"
      :showResources="showResources"
    />
    <itinerary-users
      v-if="view === 1"
      :lang="lang"
      :editing="true"
      :space="space"
      :project_id="project_id"
    />
    <smart-printing-modal
      v-if="showSmartPrinting"
      @close="closeSmartPrinting"
      :project_id="this.project_id"
    />
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
import Error from "../ErrorComponent.vue";
export default {
  props: ["lang", "space"],
  components: {
    Error,
  },
  data() {
    return {
      project: {
        id: 0,
        name: "",
        percentage: 0,
      },
      percentage: 0,
      confirmationModal: false,
      project_id: this.$route.params.project_id,
      itinerary: null,
      buttons: [
        "itinerary",
        "users",
        "resources",
        "smartPrinting",
        "taskManager",
      ],
      buttonsEditing: [
        "itinerary",
        "users",
        "resources",
        "smartPrinting",
        "taskManager",
        "save",
      ],
      view: 0,
      editing: false,
      showResources: false,
      editingCount: 0,
      confirmationItinerary: [],
      preView: 0,
      showSmartPrinting: false,
      myResources: [],
      toDeleteResources: [],
      loading: true,
      warning: false,
      info: false,
      bodyMsg: "deleteSure",
      error: {
        show: false,
        title: "permiso-title",
        body: "500-body",
      },
      traducciones: [
        {
          name: "projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "warningTxt",
          es: "Hay preguntas asociadas con respuestas que no se podrán recuperar",
          en: "There are questions associated with answers that cannot be recovered",
        },
      ],
    };
  },
  created() {
    setTimeout(() => {
      if (this.$route.path.includes("/itinerary/"))
        axios
          .get(
            `${process.env.VUE_APP_API_URL}/checkItineraryAccess/${this.space.id}/${this.project_id}}`
          )
          .then((res) => {
            if (res.data[0]) {
              if (res.data[1]) window.location.reload();
              else this.getData();
            } else {
              this.error.show = true;
            }
          });
    }, 100);
  },
  methods: {
    getData() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getPercentageByProject/${this.project_id}`
        )
        .then((response) => {
          this.percentage = response.data;
        });
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getMyProjectItinerary/${this.project_id}/${this.space.id}`
        )
        .then((res) => {
          this.project = res.data.project;
          this.itinerary = res.data.itinerary;
          this.view = 1;
          setTimeout(() => {
            this.view = 0;
            this.loading = false;
          }, 100);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.error.title = "permiso-title";
            this.error.body = "permiso-body";
          }
          this.error.show = true;
        });
    },
    updateResourcesItinerary() {
      axios.put(`${process.env.VUE_APP_API_URL}/updateResourcesItinerary`, {
        toDeleteResources: this.toDeleteResources,
      });
    },
    changeProjectName(name) {
      this.project.name = name;
    },
    updateResources(resources) {
      this.myResources = resources;
    },
    confirmationConfirm() {
      this.itinerary = this.confirmationItinerary;
      this.confirmation_itinerary = [];
      this.confirmationModal = false;
    },
    showConfirmationModal(data) {
      if (data) {
        if (data.answers) this.warning = data.answers;
        else this.warning = false;
        if (data.itinerary) this.confirmationItinerary = data.itinerary;
      }
      this.info = false;
      this.confirmationModal = !this.confirmationModal;
    },
    showConfirmationModalInfo(data) {
      this.info = true;
      this.bodyMsg = data;
      this.warning = false;
      this.confirmationModal = !this.confirmationModal;
    },
    saveItinerary() {
      this.editingCount++;
    },
    closeSmartPrinting() {
      this.showSmartPrinting = false;
    },
    showView(view) {
      if (view === 2) {
        this.view = 0;
        this.showResources = !this.showResources;
      } else if (view === 3) {
        this.showSmartPrinting = true;
      } else if (view === 4) {
        // this.editing = false;
        // this.view = 0;
        this.editingCount++;
      } else {
        this.view = view;
      }
    },
    selectProject(id) {
      this.view = -1;
      window.history.pushState(null, null, "/itinerary/" + id);
      this.project_id = id;
      this.getData();
    },
    updateItinerary(itinerary) {
      this.itinerary = itinerary[0];
      this.editing = false;
      axios
        .post(`${process.env.VUE_APP_API_URL}/updateItinerary`, {
          itinerary: itinerary[0],
          newResources: itinerary[1],
          project_id: this.project_id,
          project_name: this.project.name,
          space_id: this.space.id,
          resources: this.myResources,
          toDeleteResources: this.toDeleteResources,
        })
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          console.log(err);
          alert("Error al guardar, asegurate de rellenar todos los campos.");
          this.editing = true;
        });
    },
    setEditing() {
      if (this.editing === false) this.editing = true;
      else this.editingCount++;
    },
  },
  watch: {
    view: function () {
      if (this.view === 5) {
        this.saveItinerary();
        this.view = 0;
      }
    },
  },
  mixins: [translationMixin],
};
</script>

<template>
  <div style="height: 100vh">
    <div class="login-sidebar" style="display: table">
      <div style="display: table-row; height: 90px">
        <img
          class="login-sidebar-logo"
          :src="require('@/assets/img/sidebar/acc_logo.png')"
        />
      </div>
      <div class="register-sidebar-container">
        <img
          :src="
            require('@/assets/img/auth/' +
              (lang == 'en' ? 'ENG' : 'ESP') +
              '_Sign_Up-Side_bar_1.svg')
          "
          class="register-sidebar-texts"
        />
      </div>
    </div>
    <div class="login-main-container" v-if="!loading">
      <div class="register-titles">
        <h1 class="register-header">{{ langFilter("Welcome!") }}</h1>
        <h1 class="register-second-header">
          {{ langFilter("Lets Create") }}
          <span>{{ langFilter("your profile") }}</span
          >.
        </h1>
      </div>
      <div class="register-container">
        <div class="register-input-row">
          <div class="register-input-col m-50-50">
            <label
              >{{ langFilter("Name")
              }}<span class="signup-required-input"></span
            ></label>
            <input
              type="text"
              id="input-first-name"
              v-model="user.name"
              :placeholder="langFilter('Name')"
            />
          </div>
          <div class="register-input-col m-50-50">
            <label
              >{{ langFilter("Last name")
              }}<span class="signup-required-input"></span
            ></label>
            <input
              type="text"
              id="input-last-name"
              v-model="user.last_name"
              :placeholder="langFilter('Last name')"
            />
          </div>
        </div>
        <div class="register-input-row">
          <div class="register-input-col m-50-50">
            <label
              >{{ langFilter("Email")
              }}<span class="signup-required-input"></span
            ></label>
            <input
              type="text"
              id="input-email"
              :value="user.email"
              :placeholder="langFilter('Email')"
              disabled
            />
          </div>
          <div class="register-input-col m-50-50">
            <label
              >{{ langFilter("Username")
              }}<span class="signup-required-input"></span
            ></label>
            <input
              type="text"
              id="input-username"
              v-model="user.username"
              :placeholder="langFilter('Username')"
            />
          </div>
        </div>
        <div class="register-input-row">
          <div class="register-input-col m-50-50">
            <label>{{ langFilter("Password") }}</label>
            <div class="input-password-eye-container">
              <input
                :type="passwordVisibility ? 'text' : 'password'"
                id="input-password"
                v-model="user.password"
                :placeholder="langFilter('Password')"
                autocomplete="new-password"
              />
              <img
                v-if="!passwordVisibility"
                :src="require('@/assets/img/general/Eye_Default_Closed.png')"
                class="input-password-eye"
                @click="passwordVisible"
                @mouseenter="hoverEye"
                @mouseleave="hoverEyeLeave"
              />
              <img
                v-else
                :src="require('@/assets/img/general/Eye_Default_Open.png')"
                class="input-password-eye"
                @click="passwordVisible"
                @mouseenter="hoverEyeOpen"
                @mouseleave="hoverEyeOpenLeave"
              />
            </div>
          </div>
          <div class="register-input-col m-50-50">
            <label>{{ langFilter("Repeat Password") }}</label>
            <div class="input-password-eye-container">
              <input
                :type="passwordVisibility ? 'text' : 'password'"
                id="input-confirm-password"
                v-model="user.confirm_password"
                :placeholder="langFilter('Repeat Password')"
              />
              <img
                v-if="!passwordVisibility"
                :src="require('@/assets/img/general/Eye_Default_Closed.png')"
                class="input-password-eye"
                @click="passwordVisible"
                @mouseenter="hoverEye"
                @mouseleave="hoverEyeLeave"
              />
              <img
                v-else
                :src="require('@/assets/img/general/Eye_Default_Open.png')"
                class="input-password-eye"
                @click="passwordVisible"
                @mouseenter="hoverEyeOpen"
                @mouseleave="hoverEyeOpenLeave"
              />
            </div>
          </div>
        </div>
        <div class="register-input-row">
          <div class="register-checkbox-wrap" style="width: 700px">
            <div class="register-checkbox-container">
              <span
                class="register-checkmark-check-icon"
                v-if="terms_conditions"
                @click="terms_conditions = !terms_conditions"
              ></span>
              <input
                class="register-checkbox-input"
                type="checkbox"
                v-model="terms_conditions"
              />
              <span
                :class="
                  terms_conditions
                    ? 'register-checkmark-check'
                    : 'register-checkmark'
                "
                id="input-terms"
                @click="terms_conditions = !terms_conditions"
              ></span>
              <div class="register-checkbox-text">
                {{ langFilter("read") }}
                <a href="/terms-conditions" target="_blank">{{
                  langFilter("terms")
                }}</a>
                {{ langFilter("and") }}
                <a href="/privacy-policy" target="_blank">{{
                  langFilter("policy")
                }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="register-input-row">
          <div
            class="register-input-col invisible-margin-buttons"
            style="width: 325px"
          ></div>
          <div
            class="register-input-col invisible-margin-buttons"
            style="width: 325px"
          ></div>
          <div class="register-input-col">
            <img
              v-if="loading_submit"
              :src="require('@/assets/img/general/moon_rocket.gif')"
              style="margin-top: -50px; width: 144px; height: 144px"
            />
            <div
              v-if="!loading_submit"
              class="login-button"
              style="width: 177px; margin-top: 0px"
              @click="submit"
            >
              <p class="login-button-text">
                {{ langFilter("Log in") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="login-main-container">
      <img
        class="carga"
        style="top: calc(50% - 40px)"
        :src="require('@/assets/img/general/carga_green.gif')"
      />
    </div>
    <alertMessage :lang="lang" ref="alerts"></alertMessage>
  </div>
</template>
<script>
import translationMixin from "../../mixins/translationMixin.js";
import $ from "jquery";
import axios from "axios";
import router from "@/router";
export default {
  data() {
    return {
      lang: "es",
      loading: false,
      passwordVisibility: false,
      terms_conditions: false,
      inviter: "",
      project_id: "",
      space_id: "",
      user_type: "",
      email: "",
      send_copy: null,
      // alerts
      errors: [],
      succesful: [],
      loading_submit: false,
      user: {
        username: "",
        password: "",
        confirm_password: "",
        name: "",
        last_name: "",
        email: this.email,
        language: "es",
      },
      traducciones: [
        {
          name: "Welcome!",
          es: "¡Bienvenido!",
          en: "Welcome!",
        },
        {
          name: "Lets Create",
          es: "Vamos a",
          en: "Let's create",
        },
        {
          name: "your profile",
          es: "crear tu perfil",
          en: "your profile",
        },
        {
          name: "Username",
          es: "Nombre de usuario",
          en: "Username",
        },
        {
          name: "Password",
          es: "Contraseña",
          en: "Password",
        },
        {
          name: "Repeat Password",
          es: "Repetir Contraseña",
          en: "Repeat Password",
        },
        {
          name: "Name",
          es: "Nombre",
          en: "Name",
        },
        {
          name: "Last name",
          es: "Apellidos",
          en: "Last name",
        },
        {
          name: "Birth date",
          es: "Fecha de nacimiento",
          en: "Birth date",
        },
        {
          name: "Phone number",
          es: "Número teléfono",
          en: "Phone number",
        },
        {
          name: "Nationality",
          es: "Nacionalidad",
          en: "Nationality",
        },
        {
          name: "Language",
          es: "Lenguaje",
          en: "Language",
        },
        {
          name: "Or",
          es: "O",
          en: "Or",
        },
        {
          name: "Select file",
          es: "Seleccionar archivo",
          en: "Select file",
        },
        {
          name: "Ready, steddy... Go!",
          es: "¡Despegamos!",
          en: "Ready, steddy... Go!",
        },
        {
          name: "Your profile has been created saccessfully.",
          es: "Tu perfil ha sido creado con éxito.",
          en: "Your profile has been created saccessfully.",
        },
        {
          name: "You can now access to the platform!",
          es: "¡Ya puedes acceder a la plataforma!",
          en: "You can now access to the platform!",
        },
        {
          name: "Back",
          es: "Atrás",
          en: "Back",
        },
        {
          name: "Skip",
          es: "Saltar",
          en: "Skip",
        },
        {
          name: "Log in",
          es: "Iniciar sesión",
          en: "Log in",
        },
        {
          name: "Next",
          es: "Siguiente",
          en: "Next",
        },
        {
          name: "policy",
          es: "Política de Privacidad",
          en: "Privacy Policy",
        },
        {
          name: "terms",
          es: "Términos y Condiciones",
          en: "Terms and Conditions",
        },
        {
          name: "read",
          es: "He leído y acepto los",
          en: "I have read and accept the",
        },
        {
          name: "and",
          es: "y la",
          en: "and the",
        },
      ],
    };
  },
  created() {
    this.lang = navigator.language.substring(0, 2);
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/signup/getData/${this.$route.params.token}`
      )
      .then((response) => {
        if (response.status == 202) {
          localStorage.setItem("token", response.data.token);
          window.location.href = "/";
          return;
        }
        if (response.data == false) {
          router.push({ path: "/login" });
        }
        this.inviter = response.data.inviter;
        this.project_id = response.data.project_id;
        this.space_id = response.data.space_id;
        this.user_type = response.data.user_type;
        this.email = response.data.email;
        this.send_copy = response.data.send_copy;
        this.user.email = this.email;
      })
      .catch(() => {
        router.replace("/");
      });
  },
  methods: {
    passwordVisible() {
      this.passwordVisibility = !this.passwordVisibility;
    },
    passwordValidation() {
      if (this.user.password.length == 0) {
        $("#input-password").addClass("input-error");
        this.$refs.alerts.title = "Field required";
        this.$refs.alerts.text = "Fill the password";
        this.openErrorsAlert();
        return false;
      }
      // Validate lowercase letters
      var pass_lowercase = false;
      var pass_upperCase = false;
      var pass_numbers = false;
      var pass_length = false;

      var lowerCaseLetters = /[a-z]/g;

      if (this.user.password.match(lowerCaseLetters)) {
        pass_lowercase = true;
      } else {
        pass_lowercase = false;
      }

      // Validate capital letters
      var upperCaseLetters = /[A-Z]/g;
      if (this.user.password.match(upperCaseLetters)) {
        pass_upperCase = true;
      } else {
        pass_upperCase = false;
      }

      // Validate numbers
      var numbers = /[0-9]/g;
      if (this.user.password.match(numbers)) {
        pass_numbers = true;
      } else {
        pass_numbers = false;
      }

      // Validate length
      if (this.user.password.length >= 8) {
        pass_length = true;
      } else {
        pass_length = false;
      }
      var can = true;

      if (this.user.password == this.user.confirm_password) {
        $("#input-confirm-password").removeClass("input-error");
      } else {
        $("#input-confirm-password").addClass("input-error");
        this.$refs.alerts.title = "Incorrect data entered!";
        this.$refs.alerts.text = "The passwords you entered do not match.";
        this.openErrorsAlert();
        can = false;
      }
      if (pass_length && pass_numbers && pass_upperCase && pass_lowercase) {
        $("#input-password").removeClass("input-error");
      } else {
        $("#input-password").addClass("input-error");
        this.$refs.alerts.title = "Incorrect data entered!";
        this.$refs.alerts.text = "The password is to weak.";
        this.openErrorsAlert();
        can = false;
      }
      return can;
    },
    submit() {
      $("#input-username").removeClass("input-error");
      $("#input-password").removeClass("input-error");
      $("#input-confirm-password").removeClass("input-error");
      $("#input-terms").removeClass("input-error");

      if (this.passwordValidation()) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/signup/getUsername`, {
            username: this.user.username,
          })
          .then((response) => {
            if (response.data.length > 0) {
              $("#input-username").addClass("input-error");
              this.$refs.alerts.title = "Ups... Something went wrong!";
              this.$refs.alerts.text =
                "This Username already exists. Please try with another one.";
              this.openErrorsAlert();
              return false;
            } else {
              $("#input-username").removeClass("input-error");
            }

            if (this.user.username.length == 0) {
              $("#input-username").addClass("input-error");
              this.$refs.alerts.title = "¡Incompleted fields!";
              this.$refs.alerts.text =
                "To continue you must fill all the fields.";
              this.openErrorsAlert();
              return false;
            } else {
              $("#input-username").removeClass("input-error");
            }

            if (this.user.username.length < 5) {
              $("#input-username").addClass("input-error");
              this.$refs.alerts.title = "Username too short";
              this.$refs.alerts.text =
                "Use at least 5 characters for your username";
              this.openErrorsAlert();
              return false;
            }

            if (this.user.name == "") {
              $("#input-first-name").addClass("input-error");
              this.$refs.alerts.title = "¡Incompleted fields!";
              this.$refs.alerts.text =
                "To continue you must fill all the fields.";
              this.openErrorsAlert();
              return false;
            } else {
              $("#input-first-name").removeClass("input-error");
            }
            if (this.user.last_name == "") {
              $("#input-last-name").addClass("input-error");
              this.$refs.alerts.title = "¡Incompleted fields!";
              this.$refs.alerts.text =
                "To continue you must fill all the fields.";
              this.openErrorsAlert();
              return false;
            } else {
              $("#input-last-name").removeClass("input-error");
            }

            if (!this.terms_conditions) {
              $("#input-terms").addClass("input-error");
              $("#input-last-name").addClass("input-error");
              this.$refs.alerts.title = "¡Incompleted fields!";
              this.$refs.alerts.text =
                "Please read and accept terms and conditions and privacy policy.";
              this.openErrorsAlert();
              return false;
            } else {
              $("#input-terms").removeClass("terms-error");
            }
            this.loading_submit = true;
            const formData = new FormData();
            formData.append("username", this.user.username);
            formData.append("password", this.user.password);
            formData.append("name", this.user.name);
            formData.append("last_name", this.user.last_name);
            formData.append("email", this.user.email);
            formData.append("project_id", this.project_id);
            formData.append("space_id", this.space_id);
            formData.append("user_type", this.user_type);
            formData.append("inviter", this.inviter);
            formData.append("send_copy", this.send_copy);
            formData.append("lang", navigator.language.substring(0, 2));

            axios
              .post(
                `${process.env.VUE_APP_API_URL}/signup/submitShortSignup`,
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              )
              .then((response) => {
                if (response.data.status == "success") {
                  this.$refs.alerts.title = "¡Success!";
                  this.$refs.alerts.text =
                    "Your account has been created successfully.";
                  this.$refs.alerts.succesful = true;
                  this.openErrorsAlert();
                  localStorage.setItem("token", response.data.token);
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 3000);
                } else {
                  this.$refs.alerts.title = "Ups... Something went wrong!";
                  this.$refs.alerts.text =
                    "Please try again later or contact us.";
                  this.openErrorsAlert();
                }
              })
              .catch(function (error) {
                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                }
              });
          })
          .catch((error) => {
            console.log(error);
            return false;
          });
      }
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      clearTimeout(this.setTimeouts);

      this.errors = [];
    },
    hoverEye(el) {
      $(el.target).attr("src", "/img/register/Eye_Hover_Closed.png");
    },
    hoverEyeLeave(el) {
      $(el.target).attr("src", "/img/register/Eye_Default_Closed.png");
    },
    hoverEyeOpen(el) {
      $(el.target).attr("src", "/img/register/Eye_Hover_Open.png");
    },
    hoverEyeOpenLeave(el) {
      $(el.target).attr("src", "/img/register/Eye_Default_Open.png");
    },
  },
  mixins: [translationMixin],
};
</script>

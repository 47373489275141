<template>
  <resources-modal
    v-if="showResources"
    @updateResources="updateResources($event)"
    @closeResources="hideResources()"
    @deleteResources="deleteResources($event)"
    @saveResources="savedResources = $event"
    :lang="lang"
    :themes="themes"
    :edit="editing"
    :savedResources="savedResources"
    ref="modalResources"
  />
  <itinerary-edit-question
    v-if="editing"
    @editQuestion="editQuestionModal($event)"
    @openEditQuestionModal="openEditQuestionModal()"
    @changeType="changeType($event)"
    @editChoices="editChoices($event)"
    @editName="editQuestionNameModal($event)"
    @editPrompt="editQuestionPromptModal($event)"
    @editDesc="editQuestionDesc($event)"
    @editChoiceSelected="editChoiceSelected($event)"
    @deleteChoice="deleteChoice($event)"
    @addChoice="addChoice($event)"
    @setChoiceName="setChoiceName($event)"
    @editDescription="editDescription($event)"
    @backToOldCoices="backToOldCoices($event)"
    :itinerary="myChoices"
    :lang="lang"
    :myChoices="[]"
    ref="modalEditQuestion"
  />
  <itinerary-theme
    v-for="(theme, i) in themes"
    @addTheme="addTheme($event)"
    @editThemeDescription="editThemeDescription($event)"
    @removeTheme="removeTheme($event)"
    @editThemeName="editThemeName($event)"
    @addPart="addPart($event)"
    @removePart="removePart($event)"
    @editPartName="editPartName($event)"
    @removeQuestion="removeQuestion($event)"
    @addQuestion="addQuestion($event)"
    @editQuestionName="editQuestionName($event)"
    @showConfirmationModal="showConfirmationModal($event)"
    @editQuestion="editQuestion($event)"
    @showResources="showResourcesFunc()"
    :key="i"
    :lang="lang"
    :editing="editing"
    :theme="theme"
    :showError="showError"
    :i="i"
    :project_id="project_id"
  />
  <div v-if="themes.length === 0" class="col-12 justify-center">
    <button
      @click="addTheme(0)"
      class="itinerary-add-btn itinerary-add-btn-margin"
    >
      +
    </button>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import translationMixin from "../../mixins/translationMixin.js";
import axios from "axios";
export default {
  emits: ["updateItinerary", "showConfirmationModal", "showResources"],
  props: [
    "lang",
    "editing",
    "itinerary",
    "editingCount",
    "project_id",
    "showResources",
  ],
  data() {
    return {
      toDeleteResources: [],
      savedResources: [],
      themes: this.itinerary,
      showError: false,
      savedIds: [],
      oldChoices: {
        title: "",
        description: "",
        options: "",
        type: 0,
        prompt: "",
      },
      myChoices: {
        title: "",
        description: "",
        options: "",
        type: 0,
        prompt: "",
      },
      traducciones: [
        {
          name: "projects",
          es: "Proyectos",
          en: "Projects",
        },
      ],
    };
  },
  methods: {
    showResourcesFunc() {
      this.$emit("showResources");
    },
    deleteResources(resources) {
      resources.forEach((resource) => {
        this.toDeleteResources.push(resource);
      });
      this.$emit("toDeleteResources", this.toDeleteResources);
    },
    addTheme(i) {
      this.themes.splice(i + 1, 0, {
        preId: this.generateRandomString(),
        name: "",
        description: "",
        percentage: 0,
        parts: [
          {
            preId: this.generateRandomString(),
            name: "",
            questions: [
              {
                preId: this.generateRandomString(),
                name: "",
                description: "",
                resources: 0,
                prompt: "",
              },
            ],
          },
        ],
      });
    },
    removeTheme(i) {
      let itinerary = [...this.themes];
      itinerary.splice(i, 1);
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getAnswersByTheme/${this.project_id}/${this.themes[i].id}`
        )
        .then((response) => {
          this.$emit("showConfirmationModal", {
            itinerary: itinerary,
            answers: response.data > 0,
          });
        });
    },
    updateResources(resources) {
      resources.forEach((resource) => {
        resource.parts = resource.aparts;
        delete resource.aparts;
      });
      this.themes = resources;
      this.$emit("updateResources", resources);
      this.$emit("hideResources");
    },
    hideResources() {
      this.$emit("hideResources");
    },
    editThemeName([i, title]) {
      this.themes[i].title = title;
    },
    editThemeDescription([i, desc]) {
      this.themes[i].description = desc;
    },
    generateRandomString() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    addPart({ partIndex, themeIndex }) {
      this.themes[themeIndex].parts.splice(partIndex + 1, 0, {
        name: "",
        preId: this.generateRandomString(),
        questions: [
          {
            preId: this.generateRandomString(),
            name: "",
            description: "",
            resources: 0,
            prompt: "",
          },
        ],
      });
    },
    editPartName({ partIndex, themeIndex, name }) {
      this.themes[themeIndex].parts[partIndex].title = name;
    },

    removePart({ partIndex, themeIndex }) {
      let itineraries = JSON.parse(JSON.stringify(this.themes));
      const id = itineraries[themeIndex].parts[partIndex].id;
      itineraries[themeIndex].parts.splice(partIndex, 1);
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getAnswersByPart/${this.project_id}/${id}`
        )
        .then((response) => {
          this.$emit("showConfirmationModal", {
            itinerary: [...itineraries],
            answers: response.data > 0,
          });
        });
    },
    removeQuestion({ questionIndex, partIndex, themeIndex }) {
      let itineraries = JSON.parse(JSON.stringify(this.themes));
      const id =
        itineraries[themeIndex].parts[partIndex].questions[questionIndex].id;
      itineraries[themeIndex].parts[partIndex].questions.splice(
        questionIndex,
        1
      );
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getAnswersByQuestion/${this.project_id}/${id}`
        )
        .then((response) => {
          this.$emit("showConfirmationModal", {
            itinerary: [...itineraries],
            answers: response.data > 0,
          });
        });
    },
    addQuestion({ questionIndex, partIndex, themeIndex }) {
      this.themes[themeIndex].parts[partIndex].questions.splice(
        questionIndex + 1,
        0,
        {
          preId: this.generateRandomString(),
          name: "",
          description: "",
          resources: 0,
          type: 1,
          choices: [],
          solution_id: 0,
          prompt: "",
        }
      );
    },
    editDescription(desc) {
      this.myChoices.description = desc;
    },
    editQuestionName({ questionIndex, partIndex, themeIndex, name }) {
      this.themes[themeIndex].parts[partIndex].questions[questionIndex].name =
        name;
    },
    backToOldCoices() {
      const oldChoices = JSON.parse(JSON.stringify(this.oldChoices));
      this.myChoices.title = oldChoices.title;
      this.myChoices.description = oldChoices.description;
      this.myChoices.type = oldChoices.type;
      this.myChoices.choices = oldChoices.choices;
      this.myChoices.prompt = oldChoices.prompt;
      Modal.getOrCreateInstance(
        document.getElementById("modalEditQuestion")
      ).toggle();
    },
    setChoiceName(data) {
      this.myChoices.choices[data[1]].name = data[0];
    },
    addChoice() {
      this.myChoices.choices.push({ name: "", selected: false });
    },
    deleteChoice(choiceIndex) {
      this.myChoices.choices.splice(choiceIndex, 1);
    },
    editChoiceSelected([choiceIndex, selected]) {
      this.myChoices.choices[choiceIndex].selected = selected;
    },
    editQuestionNameModal(name) {
      this.myChoices.title = name;
    },
    editQuestionPromptModal(name) {
      this.myChoices.prompt = name;
    },
    editQuestionDesc(desc) {
      this.myChoices.description = desc;
    },
    editQuestionType(type) {
      this.myChoices.type = type;
    },
    editChoices(choices) {
      this.myChoices.choices = choices;
    },
    changeType(type) {
      this.myChoices.type = type;
    },
    editQuestion({ questionIndex, partIndex, themeIndex }) {
      this.myChoices.title =
        this.themes[themeIndex].parts[partIndex].questions[questionIndex].name;
      this.myChoices.description =
        this.themes[themeIndex].parts[partIndex].questions[
          questionIndex
        ].description;
      this.myChoices.type =
        this.themes[themeIndex].parts[partIndex].questions[questionIndex].type;
      if (
        this.themes[themeIndex].parts[partIndex].questions[questionIndex]
          .choices == null ||
        this.themes[themeIndex].parts[partIndex].questions[questionIndex]
          .choices == ""
      ) {
        this.themes[themeIndex].parts[partIndex].questions[
          questionIndex
        ].choices = [{ name: "", selected: true }];
      }
      this.myChoices.choices =
        this.themes[themeIndex].parts[partIndex].questions[
          questionIndex
        ].choices;
      this.savedIds = [themeIndex, partIndex, questionIndex];

      this.myChoices.prompt =
        this.themes[themeIndex].parts[partIndex].questions[
          questionIndex
        ].prompt;

      this.oldChoices.title = this.myChoices.title;
      this.oldChoices.description = this.myChoices.description;
      this.oldChoices.type = this.myChoices.type;
      this.oldChoices.choices = this.myChoices.choices;
      this.oldChoices.prompt = this.myChoices.prompt;

      Modal.getOrCreateInstance(
        document.getElementById("modalEditQuestion")
      ).toggle();
    },
    editQuestionModal({ title, description, type, choices, prompt }) {
      this.myChoices.title = title;
      this.myChoices.description = description;
      this.myChoices.type = type;
      this.myChoices.choices = choices;
      this.themes[this.savedIds[0]].parts[this.savedIds[1]].questions[
        this.savedIds[2]
      ].name = title;
      this.themes[this.savedIds[0]].parts[this.savedIds[1]].questions[
        this.savedIds[2]
      ].description = description;
      this.themes[this.savedIds[0]].parts[this.savedIds[1]].questions[
        this.savedIds[2]
      ].type = type;
      this.themes[this.savedIds[0]].parts[this.savedIds[1]].questions[
        this.savedIds[2]
      ].prompt = prompt;

      // remove choices with empty name
      this.themes[this.savedIds[0]].parts[this.savedIds[1]].questions[
        this.savedIds[2]
      ].choices = choices.filter((choice) => choice.name !== "");
      this.savedIds = [];
    },
    checkAllCompleted() {
      let completed = true;
      this.themes.forEach((theme) => {
        if (theme.title === "") {
          completed = false;
          return;
        }
        theme.parts.forEach((part) => {
          if (part.title === "") {
            completed = false;
            return;
          }
          part.questions.forEach((question) => {
            if (question.name === "") {
              completed = false;
              return;
            }
          });
        });
      });
      return completed;
    },
  },
  watch: {
    editingCount: function () {
      if (this.editing === true) {
        this.$emit("updateResourcesItinerary");
        if (this.checkAllCompleted()) {
          this.showError = false;
          this.$emit("updateItinerary", [this.themes, this.savedResources]);
        } else {
          this.showError = true;
          setTimeout(() => {
            const el = document.querySelector(".itinerary-user-error-input");
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        }
      }
    },
    itinerary: function () {
      this.themes = this.itinerary;
    },
  },

  mixins: [translationMixin],
};
</script>

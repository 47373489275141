<style lang="scss">
@import "../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
@import "../../../node_modules/froala-editor/css/plugins/char_counter.min.css";
@import "../../../node_modules/froala-editor/css/plugins/colors.min.css";
@import "../../../node_modules/froala-editor/css/plugins/draggable.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/font_family.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/font_size.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/inline_style.min.css";
@import "../../../node_modules/froala-editor/css/plugins/line_breaker.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/lists.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/paragraph_format.min.css";
@import "../../../node_modules/froala-editor/css/plugins/quick_insert.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/save.min.css";
@import "../../../node_modules/froala-editor/css/plugins/table.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/track_changes.min.css";
// @import "../../../node_modules/froala-editor/css/plugins/word_paste.min.css";
</style>

<template>
  <div
    :class="[
      { 'solution-answer-map-opened': mapOpened },
      ,
      'answer-princ-div-body',
    ]"
  >
    <div class="solution-div-question">
      <div
        v-if="
          (role === 1 &&
            answer.ready_correct_entrepreneur === 1 &&
            answer.send_correction_tutor === 1) ||
          (role !== 1 &&
            answer.ready_correct_tutor === 1 &&
            answer.send_correction_entrepreneur === 1)
        "
        :name="`solution-notification-circle-${answer.solution_id}`"
        :id="`solution-notification-circle-${answer.solution_id}-${answer.question_id}`"
        class="solution-notification-circle"
      />
      <p :id="'question-' + answer.question_id" class="solution-title-answer">
        {{ answerIndex + 1 }}- {{ langFilter(answer.question) }}
      </p>
      <!-- <img
        v-if="space_id == 19930 && askedGpt.includes(answerIndex)"
        @click="restartGpt(answerIndex, answer.question, false)"
        :src="Retry"
        class="chatGptRetry"
        alt="retry"
      /> -->
      <img
        v-if="role !== 1"
        @click="
          askedGpt.includes(answerIndex)
            ? askedGpt.splice(askedGpt.indexOf(answerIndex), 1)
            : askedGpt.push(answerIndex)
        "
        :src="askedGpt.includes(answerIndex) ? CloseGPT : ChatbotLogo"
        :class="
          askedGpt.includes(answerIndex) ? 'closeChatLogo' : 'chatBotLogo'
        "
        alt="ChatBotLogo"
      />
      <p
        class="solution-description-answer"
        v-html="answer.question_description"
      />
      <div v-if="askedGpt.includes(answerIndex)" class="gpt-by-question">
        <div class="selector-gpt">
          <button
            v-if="updatedPrompt !== ''"
            :class="buttonSelected === 0 && 'gpt-type-selected'"
            :disabled="askingGpt"
            @click="selectButton(0)"
          >
            Editar Prompt
          </button>
          <button
            :disabled="askingGpt"
            :class="buttonSelected === 1 && 'gpt-type-selected'"
            @click="selectButton(1)"
          >
            Pregunta Actual
          </button>
          <button
            :disabled="askingGpt"
            :class="buttonSelected === 2 && 'gpt-type-selected'"
            @click="selectButton(2)"
          >
            Chat del experto
          </button>
        </div>
        <chat-bot-project
          v-if="showChat || showQuestionChat"
          @copy-content="useResponse($event)"
          @reloadGpt="reloadChatGpt($event)"
          @reloadEditPrompt="
            editedPrompt = true;
            showQuestionChat = true;
          "
          @setEditedPrompt="buttonSelected = 1"
          @editPrompt="editPrompt()"
          @askingGpt="askingGpt = $event"
          :lang="lang"
          :project_id="project_id"
          :question_id="answer.question_id"
          :showAllChat="showAllChat"
          :showQuestionChat="showQuestionChat"
          :preButtonSelected="answer.question_id ? buttonSelected : null"
        />
      </div>
    </div>
    <div
      v-if="answer.question_type == 1 && hided !== answer.question_id"
      v-html="froalaAnswer"
      :id="role != 1 ? 'froala-activated-' + answer.question_id : ''"
      :disabled="role == 1"
      :class="[
        {
          'froala-activated': role != 1,
        },
        { 'solution-answer-body': role == 1 },
      ]"
    />
    <textarea
      v-else-if="answer.question_type == 2"
      @keyup="writeSolution($event, answer.answer_id)"
      :value="froalaAnswer"
      class="solution-answer-body"
      cols="30"
      rows="10"
      :disabled="role == 1"
    />

    <div v-else-if="answer.question_type == 0" class="solution-answer-body">
      <div class="solution-file-answer div-center">
        <button
          v-if="role != 1"
          @click="$refs.file.click()"
          class="solutions-upload-file-btn"
        >
          {{ langFilter("uploadFile") }}
        </button>
        <input
          v-if="role != 1"
          ref="file"
          type="file"
          @change="uploadSolutionFile($event, answer.question_id, answerIndex)"
          style="display: none"
        />
        <p v-if="role != 1" class="max-size">[Max: 5Mb]</p>
        <div
          v-if="answer.answer != ''"
          @click="openFile(answer.answer)"
          class="file-uploaded"
        >
          {{ getAnswerFilename(answer.answer) }}
        </div>
        <p v-else class="file-uploaded-txt">
          {{ langFilter("noAnswersYet") }}
        </p>
      </div>
    </div>
    <form v-else>
      <ul>
        <li
          v-for="(option, optionIndex) in answer.answer_options.split(
            '[;separator;]'
          )"
          :key="optionIndex"
        >
          <input
            @click="
              uncheckSameOption(
                $event,
                answer,
                option,
                answer.question_type,
                optionIndex,
                false
              )
            "
            @change="
              selectOption(
                $event,
                answer,
                option,
                answer.question_type,
                optionIndex,
                true
              )
            "
            class="solution-input-answer"
            :type="answer.question_type == 10 ? 'checkbox' : 'radio'"
            :id="`option-${answer.answer_id}-${option}`"
            :checked="checkOption(answer.answer, option)"
            :disabled="role == 1 || checkboxDisabled == answer.answer_id"
          />
          <label
            :for="`option-${answer.answer_id}-${option}`"
            :class="[
              { 'solution-label-admin': role == 1 },
              { 'solution-label-member': role != 1 },
              'solution-label-answer',
            ]"
          >
            {{ langFilter(option) }}
          </label>
        </li>
      </ul>
    </form>
    <p
      v-if="savingMsg == answer.answer_id && role != 1"
      class="solution-answer-time"
    >
      {{ langFilter("saving") }}...
    </p>
    <p v-else-if="answer.answer_date" class="solution-answer-time">
      {{ langFilter("savedAt") }}
      {{ getDataTime(1, answer.answer_date) }}
      {{ compareDates(answer.answer_date) }}
    </p>
    <div v-if="role !== 1" class="send-response-gpt">
      <p v-if="sendedRespGpt === null">Enviando...</p>
      <button v-else-if="!sendedRespGpt" @click="sendGptChatResponse()">
        Enviar respuesta al experto
      </button>
      <p v-else>Enviado</p>
    </div>
    <div
      v-if="answer.tutoring === 1 && cdti === undefined && space_id !== 22680"
      class="solution-tutor-comment-div"
    >
      <p class="answer-comment-title">{{ langFilter("comments") }}</p>
      <div
        v-html="answer.feedback_answer"
        :disabled="role != 1"
        :id="role == 1 ? 'froala-activated-' + answer.question_id : ''"
        :class="[
          { 'froala-activated': role == 1 },
          { 'solution-answer-body': role != 1 },
        ]"
      />
      <p
        v-if="savingMsg == answer.answer_id && role != 2"
        class="solution-answer-time"
      >
        {{ langFilter("saving") }}...
      </p>
      <p v-else-if="answer.feedback_answer_date" class="solution-answer-time">
        {{ langFilter("savedAt") }}
        {{ getDataTime(1, answer.feedback_answer_date) }}
        {{ compareDates(answer.feedback_answer_date) }}
      </p>
    </div>
    <!-- <div v-if="
      loadedPrev !== solution_id &&
      answers.length === answerIndex + 1 &&
      answers.length < 2
    " class="footer-solution-just-1" /> -->
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../mixins/translationMixin.js";
import FroalaEditor from "../../../node_modules/froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import $ from "jquery";
import axios from "axios";
import ChatbotLogo from "./images/chatbot.svg";
import CloseGPT from "./images/close-gpt.png";
import Send from "./images/send.png";
import Retry from "../solution/images/retry.png";
import Copy from "./images/copy.png";
import FileIcon from "./images/file.svg";
import FileGptIcon from "./images/file-gpt-icon.png";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/table.min.js";

export default {
  props: [
    "role",
    "mapOpened",
    "answer",
    "edited",
    "answerIndex",
    "answerIndex2",
    "solution_id",
    "project_id",
    "answers",
    "loadedPrev",
    "lang",
    "cdti",
    "space_id",
  ],
  data() {
    return {
      buttonSelected: 1,
      showChat: true,
      saving: "",
      savingMsg: "",
      currentDate: "",
      questionSelected: "",
      indexSelected: "0",
      oldQuestion: 0,
      checkboxDisabled: false,
      changeTriggered: false,
      askedGpt: [],
      ChatGPT: ChatbotLogo,
      CloseGPT: CloseGPT,
      ChatbotLogo: ChatbotLogo,
      Send: Send,
      Retry: Retry,
      Copy: Copy,
      FileIcon: FileIcon,
      FileGptIcon: FileGptIcon,
      filesUploaded: [],
      gptResponses: [],
      gptResponsesError: [],
      continueChatting: [],
      continueChattingInput: [],
      previousUserMessages: [],
      previousBotMessages: [],
      modifiedPrompt: "",
      searchingGpt: false,
      hided: null,
      editingGptPrompt: -1,
      showAllChat: false,
      prevStateBot: [],
      prevStateUser: [],
      showChat: true,
      sendedRespGpt: false,
      editedPrompt: false,
      selectedTab: 1,
      askingGpt: false,
      froalaAnswer: this.answer.answer,
      traducciones: [
        {
          name: "comments",
          es: "Comentarios del tutor",
          en: "Tutor comments",
        },
        {
          name: "savedAt",
          es: "Guardado a las ",
          en: "Saved at ",
        },
        {
          name: "on",
          es: "del",
          en: "on",
        },
        {
          name: "optional",
          es: "Escriba aquí los comentarios...",
          en: "Write here your comments...",
        },
        {
          name: "noOptions",
          es: "No hay opciones para esta pregunta",
          en: "There are no options for this question",
        },
        {
          name: "saving",
          es: "Guardando",
          en: "Saving",
        },
        {
          name: "uploadFile",
          es: "Subir archivo",
          en: "Upload file",
        },
        {
          name: "maxSize",
          es: "El tamaño máximo del archivo es de 5Mb",
          en: "File size must be less than 5MB",
        },
        {
          name: "noAnswersYet",
          es: "No hay respuestas aún",
          en: "No answers yet",
        },
        {
          name: "noFile",
          es: "No hay archivo",
          en: "No file",
        },
        {
          name: "Which of the following sectors are your company working on?",
          es: "¿En qué sector está trabajando su empresa?",
          en: "Which of the following sectors are your company working on?",
        },
        {
          name: "Manufacturing",
          es: "Manufactura",
          en: "Manufacturing",
        },
        {
          name: "Service",
          es: "Servicio",
          en: "Service",
        },
        {
          name: "Digitalization & IOT",
          es: "Digitalización e IOT",
          en: "Digitalization & IOT",
        },
        {
          name: "Construction",
          es: "Construcción",
          en: "Construction",
        },
        {
          name: "Pharma",
          es: "Farmacéutica",
          en: "Pharma",
        },
        {
          name: "Education",
          es: "Educación",
          en: "Education",
        },
        {
          name: "Hospitality",
          es: "Hospitalidad",
          en: "Hospitality",
        },
        {
          name: "Agriculture Forestry And Fishing",
          es: "Agricultura, silvicultura y pesca",
          en: "Agriculture Forestry And Fishing",
        },
        {
          name: "Electricity, Gas, Steam And Air Conditioning Suppl",
          es: "Suministro de electricidad, gas, vapor y aire acondicionado",
          en: "Electricity, Gas, Steam And Air Conditioning Suppl",
        },
        {
          name: "Transportation And Storage",
          es: "Transporte y almacenamiento",
          en: "Transportation And Storage",
        },
        {
          name: "Information And Communication",
          es: "Información y comunicación",
          en: "Information And Communication",
        },
        {
          name: "Financial And Insurance Activities",
          es: "Actividades financieras y de seguros",
          en: "Financial And Insurance Activities",
        },
        {
          name: "Public Administration And Defense, Compulsary Social Security",
          es: "Administración pública y defensa, seguridad social obligatoria",
          en: "Public Administration And Defense, Compulsary Social Security",
        },
        {
          name: "Human Health And Social Work Securities",
          es: "Actividades de salud humana y servicios sociales",
          en: "Human Health And Social Work Securities",
        },
        {
          name: "Other Activities",
          es: "Otras actividades",
          en: "Other Activities",
        },
        {
          name: "Does your benefit maintain with any levels of sales?",
          es: "¿Su beneficio se mantiene con cualquier nivel de ventas?",
          en: "Does your benefit maintain with any levels of sales?",
        },
        {
          name: "Yes, I have the same benefit with any amount of sales",
          es: "No, mi beneficio disminuye con más ventas que tenemos",
          en: "Yes, I have the same benefit with any amount of sales",
        },
        {
          name: "No, my benefit reduces with more sales we have",
          es: "No, mi beneficio aumenta con más ventas que tenemos",
          en: "No, my benefit reduces with more sales we have",
        },
        {
          name: "No, my benefit increases with more sales we have",
          es: "No he empezado a vender",
          en: "No, my benefit increases with more sales we have",
        },
        {
          name: "I have not started to sell",
          es: "No estoy seguro",
          en: "I have not started to sell",
        },
        {
          name: "",
          es: "Sí, tengo el mismo beneficio con cualquier cantidad de ventas",
          en: "",
        },
        {
          name: "I am unsure",
          es: "No estoy seguro",
          en: "I am unsure",
        },
        {
          name: "Technology Readiness Level. Which of the following best describes the current:",
          es: "Nivel de preparación tecnológica. ¿Cuál de los siguientes describe mejor la situación actual?",
          en: "Technology Readiness Level. Which of the following best describes the current:",
        },
        {
          name: "TRL 1 – Basic Principles Observed and Reported",
          es: "TRL 1 - Principios básicos observados y reportados",
          en: "TRL 1 – Basic Principles Observed and Reported",
        },
        {
          name: "TRL 2 – Potential Application Validated",
          es: "TRL 2 - Aplicación potencial validada",
          en: "TRL 2 – Potential Application Validated",
        },
        {
          name: "TRL 3. Experimental proof of concept",
          es: "TRL 3 - Prueba de concepto experimental",
          en: "TRL 3. Experimental proof of concept",
        },
        {
          name: "TRL 4. Component and/or breadboard validation in laboratory environment",
          es: "TRL 4 - Validación de componentes y/o prototipo en un ambiente de laboratorio",
          en: "TRL 4. Component and/or breadboard validation in laboratory environment",
        },
        {
          name: "TRL 5 – Component and/or breadboard validation in relevant environment",
          es: "TRL 5 - Validación de componentes y/o prototipo en un ambiente relevante",
          en: "TRL 5 – Component and/or breadboard validation in relevant environment",
        },
        {
          name: "TRL 6 – System/subsystem model or prototype demonstration in a relevant environment",
          es: "TRL 6 - Demostración de modelo o prototipo de sistema/subsistema en un ambiente relevante",
          en: "TRL 6 – System/subsystem model or prototype demonstration in a relevant environment",
        },
        {
          name: "TRL 7 – System prototype demonstration in an operational environment",
          es: "TRL 7 - Demostración de prototipo de sistema en un ambiente operacional",
          en: "TRL 7 – System prototype demonstration in an operational environment",
        },
        {
          name: "TRL 8 – Actual system completed and qualified through test and demonstration",
          es: "TRL 8 - Sistema real completado y calificado a través de pruebas y demostración",
          en: "TRL 8 – Actual system completed and qualified through test and demonstration",
        },
        {
          name: "TRL 9 – Actual system proven through successful mission operations",
          es: "TRL 9 - Sistema real demostrado a través de operaciones de misión exitosas",
          en: "TRL 9 – Actual system proven through successful mission operations",
        },
        {
          name: "It does not apply to my bussiness",
          es: "No aplica a mi negocio",
          en: "It does not apply to my bussiness",
        },
        {
          name: "How many leads you contact convert into a paying client?",
          es: "¿Cuántos clientes potenciales que contacta convierte en clientes que pagan?",
          en: "How many leads you contact convert into a paying client?",
        },
        {
          name: "Less than 25%",
          es: "Menos del 25%",
          en: "Less than 25%",
        },
        {
          name: "Between 26 - 50%",
          es: "Entre el 26 - 50%",
          en: "Between 26 - 50%",
        },
        {
          name: "Between 51-75%",
          es: "Entre el 51-75%",
          en: "Between 51-75%",
        },
        {
          name: "More than 76%",
          es: "Más del 76%",
          en: "More than 76%",
        },
        {
          name: "I am unsure of the conversion rate",
          es: "No estoy seguro de la tasa de conversión",
          en: "I am unsure of the conversion rate",
        },
        {
          name: "How much have you raised on the first five years of your company/startup (founders funds, FFF Funds, Angel Rounds and Venture Capital)?",
          es: "¿Cuánto ha recaudado en los primeros cinco años de su empresa/startup (fondos de los fundadores, rondas de amigos, familiares y ángeles y capital de riesgo)?",
          en: "How much have you raised on the first five years of your company/startup (founders funds, FFF Funds, Angel Rounds and Venture Capital)?",
        },
        {
          name: "Less than 250.000€",
          es: "Menos de 250.000€",
          en: "Less than 250.000€",
        },
        {
          name: "More than 5.000.000€",
          es: "Más de 5.000.000€",
          en: "More than 5.000.000€",
        },
        {
          name: "How would you best describe your business experience?",
          es: "¿Cómo describiría mejor su experiencia empresarial?",
          en: "How would you best describe your business experience?",
        },
        {
          name: "Experience as a C-Level executive in any sector",
          es: "Experiencia como ejecutivo de alto nivel en cualquier sector",
          en: "Experience as a C-Level executive in any sector",
        },
        {
          name: "Experience in your industry sector",
          es: "Experiencia en su sector industrial",
          en: "Experience in your industry sector",
        },
        {
          name: "Experience in Sales or Technology",
          es: "Experiencia en ventas o tecnología",
          en: "Experience in Sales or Technology",
        },
        {
          name: "No Experience",
          es: "No tengo experiencia",
          en: "No Experience",
        },
        {
          name: "Dedication to the project (Choose only one):",
          es: "Dedicación al proyecto (elija solo uno):",
          en: "Dedication to the project (Choose only one):",
        },
        {
          name: "1 founder dedicated part-time",
          es: "1 fundador dedicado a tiempo parcial",
          en: "1 founder dedicated part-time",
        },
        {
          name: "1 founder dedicated full-time",
          es: "1 fundador dedicado a tiempo completo",
          en: "1 founder dedicated full-time",
        },
        {
          name: "2 founders dedicated part-time",
          es: "2 fundadores dedicados a tiempo parcial",
          en: "2 founders dedicated part-time",
        },
        {
          name: "1 founder full-time and 1 part-time",
          es: "1 fundador a tiempo completo y 1 a tiempo parcial",
          en: "1 founder full-time and 1 part-time",
        },
        {
          name: "2 founders, or more, dedicated full-time",
          es: "2 fundadores o más dedicados a tiempo completo",
          en: "2 founders, or more, dedicated full-time",
        },
        {
          name: "How do you sell to your clients?",
          es: "¿Cómo vende a sus clientes?",
          en: "How do you sell to your clients?",
        },
        {
          name: "What stage is your product at the moment?",
          es: "¿En qué etapa se encuentra su producto en este momento?",
          en: "What stage is your product at the moment?",
        },
        {
          name: "I have developed wireframes/designs/blueprints/models",
          es: "He desarrollado wireframes/diseños/planos/modelos",
          en: "I have developed wireframes/designs/blueprints/models",
        },
        {
          name: "I have developed a Minimum Viable Product",
          es: "He desarrollado un producto mínimo viable (MVP)",
          en: "I have developed a Minimum Viable Product",
        },
        {
          name: "I have launched my MVP have using customers",
          es: "He lanzado mi MVP y tengo clientes usándolo",
          en: "I have launched my MVP have using customers",
        },
        {
          name: "It is just an idea at the moment",
          es: "Solo es una idea en este momento",
          en: "It is just an idea at the moment",
        },
        {
          name: "It does not apply to my bussiness",
          es: "No aplica a mi negocio",
          en: "It does not apply to my bussiness",
        },
        {
          name: "What is the size of target market in total sales?",
          es: "¿Cuál es el tamaño del mercado objetivo en ventas totales?",
          en: "What is the size of target market in total sales?",
        },
        {
          name: "<$50 Million",
          es: "Menos de 50 millones de euros",
          en: "<$50 Million",
        },
        {
          name: "$50 - $100 Million",
          es: "50 - 100 millones de euros",
          en: "$50 - $100 Million",
        },
        {
          name: ">$100 Million",
          es: "Más de 100 millones de euros",
          en: ">$100 Million",
        },
        {
          name: "Looking at the present, How would you rate the success of your company/project?",
          es: "11- Mirando al presente, ¿cómo calificaría el éxito de su empresa/proyecto?",
          en: "Looking at the present, How would you rate the success of your company/project?",
        },
        {
          name: "gptError",
          es: "Ha ocurrido un error al conectar con el Asistente, por favor inténtelo de nuevo de más tarde o contacte con:",
          en: "An error has occurred while connecting to the Assistant, please try again later or contact with:",
        },
        {
          name: "tryAgain",
          es: "Volver a Intentar",
          en: "Try Again",
        },
        {
          name: "continueChatting",
          es: "Seguir la Conversación",
          en: "Continue Chatting",
        },
      ],
    };
  },
  computed: {
    currentQuestionClass() {
      return this.showAllChat ? "" : "gpt-type-selected";
    },
    projectChatClass() {
      return this.showAllChat ? "gpt-type-selected" : "";
    },
  },
  methods: {
    selectButton(value) {
      if (!this.askingGpt) {
        if (value === 0) this.editPrompt();
        this.toggleChat(value === 2);
        this.showQuestionChat = value === 1;
        this.buttonSelected = value;
      }
    },
    sendGptChatResponse() {
      this.sendedRespGpt = null;
      const params = {
        id: this.answer.answer_id,
        solution_id: this.solution_id,
        project_id: this.project_id,
        question_id: this.answer.question_id,
        question_type: this.answer.question_type,
        role: this.role,
        indexSelected: this.indexSelected,
      };
      axios
        .post(`${process.env.VUE_APP_API_URL}/chatgpt/autosaveParts`, params)
        .then(() => {
          this.sendedRespGpt = true;
          this.$emit("reloadChatGpt");
          this.showChat = false;
          this.$nextTick(() => {
            this.showChat = true;
          });
          setTimeout(
            function () {
              this.sendedRespGpt = false;
            }.bind(this),
            3000
          );
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            let message = error.response.data.message;
            if (message.includes("Invalid 'content': string too long")) {
              alert(
                "El contenido de la respuesta es demasiado largo para ser procesado por el Asistente, por favor reduzcalo a un máximo de 32000 caracteres."
              );
            } else if (message.includes("Attempt to read property")) {
              alert(
                "Para poder enviar la respuesta a el Asistente, la respuesta no puede estar vacía."
              );
            } else {
              alert(message);
            }
          } else {
            alert("Error enviando la respuesta al Asistente");
          }
          console.error(error);
        });
    },
    checkEditPrompt() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getProjectUserChatGpt/${this.project_id}/${this.answer.question_id}`
        )
        .then((res) => {
          if (res.data) {
            this.buttonSelected = 1;
            return true;
          }
          this.buttonSelected = 0;
          return false;
        })
        .catch((error) => {
          console.log(error);
          this.buttonSelected = 0;
          return false;
        });
    },
    getQuestionChat() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getQuestionChat/${this.project_id}/${this.answer.question_id}`
        )
        .then((res) => {
          if (res.data) return true;
          return false;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    editPrompt() {
      axios.delete(
        `${process.env.VUE_APP_API_URL}/chatgpt/removeFirstMessage/${this.project_id}/${this.answer.question_id}`
      );
      this.editedPrompt = false;
      this.showAllChat = false;
      this.askedGpt.splice(this.askedGpt.indexOf(this.answerIndex), 1);
      setTimeout(
        function () {
          this.askedGpt.push(this.answerIndex);
        }.bind(this),
        100
      );
    },
    toggleChat(value) {
      this.showAllChat = value;
    },
    initFroala() {
      const _token = $('meta[name="csrf-token"]').attr("content");
      new FroalaEditor("#froala-activated-" + this.answer.question_id, {
        placeholderText: this.langFilter("optional"),
        fontFamilyDefaultSelection: "Poppins",
        height: 174,
        attribution: false,
        trackChangesEnabled: true,
        showChangesEnabled: true,
        tableResizerOffset: 10,
        tableResizingLimit: 50,
        paragraphFormat: {
          N: "Normal",
          H1: "Heading 1",
          H2: "Heading 2",
        },
        wordDeniedTags: [
          "table",
          "form",
          "hr",
          "input",
          "select",
          "option",
          "textarea",
          "tr",
          "td",
          "th",
          "tbody",
          "thead",
          "tfoot",
          "col",
          "colgroup",
          "caption",
          "style",
          "script",
          "link",
          "meta",
          "title",
          "html",
        ],
        paragraphFormatSelection: true,
        paragraphMultipleStyles: true,
        wordPasteKeepFormatting: true,
        wordPasteModal: true,
        pastePlain: false, // Allow pasting with formatting
        pasteAllowedStyleProps: [
          "font",
          "font-size",
          "color",
          "background-color",
          "text-align",
        ], // Add allowed styles
        pasteDeniedAttrs: ["style", "class"], // Deny certain attributes if needed

        tableInsertButtons: ["tableBack"],
        tableCellMultipleStyles: true,
        tableColorsButtons: ["tableBack"],
        tableDefaultWidth: "200px",
        tableEditButtons: [
          "tableRows",
          "tableColumns",
          "tableCells",
          "tableCellVerticalAlign",
          "tableRemove",
        ],
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "alignLeft",
              "alignCenter",
              "alignRight",
              "alignJustify",
              "strikeThrough",
              "table",
              "|",
              "paragraphFormat",
              "undo",
              "redo",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
              "formatOL",
              "formatUL",
            ],
          },
          moreParagraph: {
            buttons: ["table", "outdent", "indent"],
          },
          moreRich: {
            buttons: ["table", "insertLink", "insertImage", "insertFile"],
          },
          moreMisc: {
            buttons: ["undo", "redo"],
            align: "right",
            buttonsVisible: 2,
          },
        },
        fontFamily: {
          "Roboto,sans-serif": "Roboto",
          "Oswald,sans-serif": "Oswald",
          "Montserrat,sans-serif": "Montserrat",
          "'Open Sans Condensed',sans-serif": "Open Sans Condensed",
        },
        fontFamilySelection: true,
        dragInline: false,
        lineBreakerTags: ["table", "hr", "form"],
        listAdvancedTypes: true,
        pluginsEnabled: [
          "align",
          "charCounter",
          "colors",
          "draggable",
          "fontFamily",
          "fontSize",
          "inlineStyle",
          "image",
          "link",
          "lineBreaker",
          "lists",
          "paragraphFormat",
          "quickInsert",
          "save",
          "table",
          "track_changes",
          "trackChanges",
          "wordPaste",
        ],
        linkAlwaysBlank: false,
        quickInsertButtons: ["image", "table", "embedly"],
        key: process.env.VUE_APP_FROALA_KEY,
        imageUploadMethod: "POST",
        imageUploadParams: {
          _token: _token,
        },
        imageAllowedTypes: ["jpeg", "jpg", "png"],
        imageUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/img",
        fileUploadMethod: "POST",
        fileUploadParams: {
          _token: _token,
        },
        fileUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/files",
        videoUploadMethod: "POST",
        videoUploadParams: {
          _token: _token,
        },
        videoUploadURL: process.env.VUE_APP_API_URL + "/froala/upload/videos",

        //autosave
        saveInterval: 2000,
        saveParam: "content",
        saveURL: process.env.VUE_APP_API_URL + "/solution/autosave",
        saveMethod: "POST",
        saveParams: {
          id: this.answer.answer_id,
          solution_id: this.solution_id,
          project_id: this.project_id,
          question_id: this.answer.question_id,
          question_type: this.answer.question_type,
          role: this.role,
          indexSelected: this.indexSelected,
          _token: _token,
        },

        events: {
          // Handle paste event to ensure content can be pasted
          "paste.before": function (clipboardData) {
            const clipboardText = clipboardData.getData("text/plain");
            if (clipboardText && clipboardText.startsWith("http")) {
              // Insert the URL as plain text
              this.html.insert(clipboardText, false);
              return false; // Prevent default paste behavior
            }
            return true; // Allow other content types
          },

          //autosave
          "save.before": function () {
            this.savingMsg = this.answer.answer_id;
          }.bind(this),
          "save.after": function (request) {
            this.sendedRespGpt = false;
            const data = JSON.parse(request);

            const answerId = this.answer.answer_id;

            if (data["deleted"]) {
              this.updateDateAnswer(null);
              this.answer.answer_id = null;
            } else {
              this.updateDateAnswer(data["date"]);
              this.answer.answer_id = data["last_answer"];
            }
            this.$emit("prepareAnswers", [
              this.solution_id,
              this.answer.question_id,
              answerId,
              data,
              this.answerIndex2,
              this.answerIndex,
            ]);
            this.reloadData();
          }.bind(this),
          "save.error": function () {
            // console.log("error");
          },
        },
      });
    },
    reloadChatGpt() {
      this.$emit("reloadChatGpt");
      this.showChat = false;
      this.$nextTick(() => {
        this.showChat = true;
      });
    },
    openFileInput(event) {
      event.preventDefault();
      this.$refs.fileInput.click();
    },
    removeFileGpt(index) {
      this.filesUploaded.splice(index, 1);
    },
    uploadedFileGpt() {
      const file = this.$refs.fileInput.files[0];
      for (let i = 0; i < this.filesUploaded.length; i++) {
        if (this.filesUploaded[i].title === file.name) {
          return;
        }
      }
      this.filesUploaded.push({
        title: file.name,
        url: URL.createObjectURL(file),
        type: file.type,
        file: file,
      });
    },
    saveGptPromt(index, question_id) {
      if (this.modifiedPrompt !== "") {
        this.previousUserMessages[index] = this.modifiedPrompt;
        axios
          .post(`${process.env.VUE_APP_API_URL}/chatgpt/changeGptPrompt`, {
            question_id: question_id,
            gpt_prompt: this.modifiedPrompt,
          })
          .then(() => {
            this.searchingGpt = true;

            const type = this.preButtonSelected
              ? this.preButtonSelected
              : this.buttonSelected > 0
              ? this.buttonSelected
              : 1;
            this.askingGpt = true;
            axios
              .post(
                `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
                {
                  project_id: this.project_id,
                  previousUserMessages: this.previousUserMessages,
                  previousBotMessages: this.previousBotMessages,
                }
              )
              .then((res) => {
                const response = res.data.generated_response;
                this.previousBotMessages[index] = response;
                this.searchingGpt = false;
              })
              .catch(() => {
                this.gptResponsesError[i] = true;
              })
              .finally(() => {
                this.askingGpt = false;
              });
            this.modifiedPrompt = "";
            this.editingGptPrompt = -1;
          })
          .catch((error) => {
            alert("Error modificando el prompt del Asistente");
            console.error(error);
          });
      }
    },
    setEditingGptPrompt(index, prompt) {
      this.editingGptPrompt = index;
      this.modifiedPrompt = prompt;
    },
    useResponse(response) {
      this.answer.answer = response;
      this.hided = this.answer.question_id;
      setTimeout(
        function () {
          this.hided = null;
          axios
            .post(`${process.env.VUE_APP_API_URL}/solution/autosave`, {
              id: this.answer.answer_id,
              solution_id: this.solution_id,
              project_id: this.project_id,
              question_id: this.answer.question_id,
              question_type: this.answer.question_type,
              role: this.role,
              content: response,
            })
            .then((response) => {
              if (response.data.deleted) {
                this.updateDateAnswer(null);
                this.answer.answer_id = null;
                this.$parent.answers[this.answerIndex].answer_id = null;
              } else {
                this.updateDateAnswer(response.data.date);
                this.answer.answer_id = response.data.last_answer;
                this.$parent.answers[this.answerIndex].answer_id =
                  response.data.last_answer;
              }
              this.savingMsg = false;
              this.$emit("reloadPercentage");
              this.$emit("updateSendToCorrect");
              setTimeout(
                function () {
                  this.initFroala();
                }.bind(this),
                300
              );
            });
        }.bind(this),
        100
      );
    },
    stripHtml(e) {
      e.preventDefault();
      const text = window.getSelection().toString();
      e.clipboardData.setData("text/plain", text);
    },
    getDataTime(type, datatime) {
      if (datatime !== null) {
        datatime = datatime.split(" ");
        if (type == 1) {
          const time = datatime[1].split(":");
          datatime[1] = `${time[0]}:${time[1]}`;
        } else {
          const time = datatime[0].split("-");
          datatime[0] = `${time[2]}/${time[1]}/${time[0]}`;
        }
        return datatime[type];
      }
    },
    checkOption(answer, option) {
      try {
        const data = answer.split("[;separator;]");
        for (let i = 0; i < data.length; i++) {
          //remove spaces from data[i] and option
          data[i] = data[i].replace(/\s/g, "");
          option = option.replace(/\s/g, "");

          if (data[i] == option) {
            return true;
          }
        }
        return false;
      } catch (error) {
        return false;
      }
    },

    continueChattingGpt(e, i) {
      e.preventDefault();
      const prompt = this.continueChattingInput[i];
      if (prompt == undefined || prompt == "")
        this.continueChatting.splice(this.continueChatting.indexOf(i), 1);
      else this.continueAskChatGpt(i, prompt);
    },

    continueAskChatGpt(i, prompt) {
      this.gptResponses.splice(this.gptResponses.indexOf(i), 1);
      this.continueChatting.splice(this.continueChatting.indexOf(i), 1);
      this.continueChattingInput[i] = "";
      this.previousUserMessages.push(prompt);
      this.searchingGpt = true;
      let formData = new FormData();
      formData.append("project_id", this.project_id);

      this.filesUploaded.forEach((file, index) => {
        formData.append("files[" + index + "]", file.file);
      });

      axios
        .post(`${process.env.VUE_APP_API_URL}/uploadChatGptFiles`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.filesUploaded = [];

          const type = this.preButtonSelected
            ? this.preButtonSelected
            : this.buttonSelected > 0
            ? this.buttonSelected
            : 1;
          this.askingGpt = true;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
              {
                project_id: this.project_id,
                previousUserMessages: this.previousUserMessages,
                previousBotMessages: this.previousBotMessages,
              }
            )
            .then((res) => {
              const response = res.data.generated_response;
              this.gptResponsesError[i] = false;
              this.gptResponses[i] = response;
              this.previousBotMessages.push(response);
              this.searchingGpt = false;
            })
            .catch(() => {
              this.gptResponsesError[i] = true;
            })
            .finally(() => {
              this.askingGpt = false;
            });
        });
    },

    restartGpt(i, prompt, tryAgain) {
      this.previousUserMessages = [];
      this.previousBotMessages = [];
      this.gptResponses[i] = undefined;
      this.askChatGpt(i, prompt, tryAgain);
    },

    setOldStateGptChat() {
      this.previousUserMessages = this.prevStateUser;
      this.previousBotMessages = this.prevStateBot;
    },

    async getProjectGptChat() {
      try {
        const type = this.preButtonSelected
          ? this.preButtonSelected
          : this.buttonSelected > 0
          ? this.buttonSelected
          : 1;
        this.askingGpt = true;
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`
        );
        const prevBot = this.previousBotMessages;
        const prevUser = this.previousUserMessages;
        this.prevStateBot = prevBot;
        this.prevStateUser = prevUser;
        this.previousBotMessages = res.data.previousBotMessages;
        this.previousUserMessages = res.data.previousUserMessages;
        //scroll to the bottom of gpt-help-div-content
        this.$nextTick(() => {
          const gptHelpDivContent = document.getElementById(
            "gpt-help-div-content"
          );
          if (gptHelpDivContent) {
            gptHelpDivContent.scrollTop = gptHelpDivContent.scrollHeight;
          }
        });
        this.askingGpt = true;
      } catch (error) {
        console.error(error);
        // this.$notify({
        //   title: "Error",
        //   message: "Error obteniendo el chat del Asistente",
        //   type: "error",
        // });
        alert("Error obteniendo el chat del Asistente");
        this.askingGpt = false;
      }
    },
    askChatGpt(i, prompt, tryAgain) {
      if (this.askedGpt.indexOf(i) == -1 || tryAgain) {
        if (!tryAgain) this.askedGpt.push(i);
        this.gptResponsesError[i] = false;
        if (this.gptResponses[i] === undefined) {
          this.previousUserMessages.push(prompt);
          this.searchingGpt = true;

          const type = this.preButtonSelected
            ? this.preButtonSelected
            : this.buttonSelected > 0
            ? this.buttonSelected
            : 1;
          this.askingGpt = true;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
              {
                project_id: this.project_id,
                previousUserMessages: this.previousUserMessages,
                previousBotMessages: this.previousBotMessages,
              }
            )
            .then((res) => {
              const response = res.data.generated_response;
              this.gptResponsesError[i] = false;
              this.gptResponses[i] = response;
              this.previousBotMessages.push(response);
              this.searchingGpt = false;
            })
            .catch(() => {
              this.gptResponsesError[i] = true;
            })
            .finally(() => {
              this.askingGpt = false;
            });
        }
      } else {
        //remove from array
        this.askedGpt.splice(this.askedGpt.indexOf(i), 1);
      }
    },

    uncheckSameOption(event, answer, option, type, index) {
      this.checkboxDisabled = this.answer.answer_id;
      setTimeout(() => {
        if (type == 5) {
          if (!this.changeTriggered) {
            let url = "solution/updateCheckboxAnswer";
            if (this.cdti) url = "cdti/insertInvertibility";
            axios
              .post(`${process.env.VUE_APP_API_URL}/${url}`, {
                answer_id: answer.answer_id,
                option: "",
                solution_id: this.solution_id,
                project_id: this.project_id,
                role: this.role,
                type: type,
                question_id: answer.question_id,
                indexSelected: this.indexSelected,
                time: false,
              })
              .then((response) => {
                this.$parent.answers[this.answerIndex].answer = null;
                this.updateDateAnswer(null);
                this.checkboxDisabled = 0;
              });
          } else {
            this.changeTriggered = false;
          }
        }
      }, 200);
    },

    saveChattingInput(e, i) {
      this.continueChattingInput[i] = e.target.value;
    },

    selectOption(event, answer, option, type, index) {
      try {
        this.changeTriggered = true;
        this.checkboxDisabled = this.answer.answer_id;
        index = index.toString();
        if (this.role != 1) {
          let solutionAnswer = option;
          //order
          if (type !== 5) {
            if (this.oldQuestion != answer.question_id) {
              this.indexSelected = index;
              this.oldQuestion = answer.question_id;
            } else {
              if (!event.target.checked) {
                // delete the index from the array
                const order = this.indexSelected.split("[;separator;]");
                // remove the index from the array only if it's in the array
                if (order.indexOf(index) != -1) {
                  order.splice(order.indexOf(index), 1);
                }
                this.indexSelected = order.join("[;separator;]");
              } else {
                // split separator in a valiable
                const order = this.indexSelected.split("[;separator;]");
                // check if the index is already selected
                if (order.indexOf(index) != -1) {
                  // remove the index from the array
                  order.splice(order.indexOf(index), 1);
                  // add the index to the end of the array
                  order.push(index);
                  // join the array with the separator
                  this.indexSelected = order.join("[;separator;]");
                } else {
                  // add the index to the end of the array
                  if (this.indexSelected == "") {
                    this.indexSelected = index;
                  } else {
                    this.indexSelected =
                      this.indexSelected + "[;separator;]" + index;
                  }
                }
              }
              // delete the [;separator;] if is blank
              if (this.indexSelected == "[;separator;]") {
                this.indexSelected = "";
              }
            }
          }

          if (
            type !== 5 &&
            this.$parent.answers[this.answerIndex].answer !== null
          ) {
            solutionAnswer = "";
            const data =
              this.$parent.answers[this.answerIndex].answer.split(
                "[;separator;]"
              );
            if (!event.target.checked) {
              data.splice(data.indexOf(option), 1);
              data.forEach((el, i) => {
                solutionAnswer += el;
                if (i != data.length - 1) {
                  solutionAnswer += "[;separator;]";
                }
              });
            } else {
              solutionAnswer = this.$parent.answers[this.answerIndex].answer;
              if (solutionAnswer !== "") {
                solutionAnswer += "[;separator;]";
              }
              solutionAnswer += option;
            }
          } else {
            this.indexSelected = index;
          }
          let url = "solution/updateCheckboxAnswer";
          if (this.cdti) url = "cdti/insertInvertibility";
          axios
            .post(`${process.env.VUE_APP_API_URL}/${url}`, {
              answer_id: answer.answer_id,
              option: solutionAnswer,
              solution_id: this.solution_id,
              project_id: this.project_id,
              role: this.role,
              type: type,
              question_id: answer.question_id,
              indexSelected: this.indexSelected,
              time: true,
            })
            .then((response) => {
              if (response.data.deleted) {
                this.$parent.answers[this.answerIndex].answer = null;
                this.updateDateAnswer(null);
              } else {
                this.$parent.answers[this.answerIndex].answer = solutionAnswer;
                this.$parent.answers[this.answerIndex].answer_id =
                  response.data.last_answer;
                this.updateDateAnswer(response.data.date);
              }
              if (!this.cdti) {
                this.$emit("reloadPercentage");
                this.$emit("updateSendToCorrect");
              }
              setTimeout(() => {
                this.checkboxDisabled = 0;
              }, 300);
            });
        }
      } catch (e) {
        console.log(e);
      }
    },
    updateDateAnswer(request) {
      if (request !== null) {
        this.role == 1
          ? (this.answer.feedback_answer_date = request)
          : (this.answer.answer_date = request);
        this.$emit("editedSolution", true);
      } else {
        this.role == 1
          ? (this.answer.feedback_answer_date = null)
          : (this.answer.answer_date = null);
        this.$emit("editedSolution", false);
      }
      this.answer.interaction_entrepreneur = 0;
      this.answer.interaction_tutor = 0;
    },
    getAnswerFilename(answer) {
      try {
        let filename = answer.split("_m8y0_");
        filename = filename[1].split('"');
        return filename[0];
      } catch (e) {
        return this.langFilter("noFile");
      }
    },
    openFile(answer) {
      const url = answer.split('"');
      window.open(url[1], "_blank");
    },
    uploadSolutionFile(el, question_id, index) {
      const file = el.target.files[0];
      if (file.size > 5000000) {
        this.$emit("errorFileUpload");
      } else {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("project", this.project_id);
        formData.append("solution", this.solution_id);
        formData.append("question", question_id);
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/solution/answerUpload`,
            formData
          )
          .then((response) => {
            this.$parent.answers[index].answer = response.data[0];
            this.answer.answer = response.data[0];
            this.updateDateAnswer(response.data[1]);
            this.$emit("reloadPercentage");
            this.reloadData();
          });
      }
    },
    writeSolution(event, answer_id) {
      if (this.role != 1) {
        if (this.savingMsg == answer_id) {
          clearTimeout(this.saving);
        }
        this.savingMsg = answer_id;
        this.saving = setTimeout(
          function () {
            axios
              .post(`${process.env.VUE_APP_API_URL}/solution/autosave`, {
                id: this.answer.answer_id,
                solution_id: this.solution_id,
                project_id: this.project_id,
                question_id: this.answer.question_id,
                question_type: this.answer.question_type,
                role: this.role,
                content: event.target.value,
              })
              .then((response) => {
                if (response.data.deleted) {
                  this.updateDateAnswer(null);
                  this.answer.answer_id = null;
                  this.$parent.answers[this.answerIndex].answer_id = null;
                } else {
                  this.updateDateAnswer(response.data.date);
                  this.answer.answer_id = response.data.last_answer;
                  this.$parent.answers[this.answerIndex].answer_id =
                    response.data.last_answer;
                }
                this.savingMsg = false;
                this.$emit("reloadPercentage");
                this.$emit("updateSendToCorrect");
              });
          }.bind(this),
          2500
        );
      }
    },
    compareDates(answerDate) {
      answerDate = this.getDataTime(0, answerDate);
      if (answerDate != this.currentDate) {
        return this.langFilter("on") + " " + answerDate;
      }
    },
    reloadData() {
      this.savingMsg = false;
      this.$emit("reloadPercentage");
      this.$emit("updateSendToCorrect");
    },
  },
  created() {
    const date = new Date();
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    this.currentDate = dd + "/" + mm + "/" + yyyy;
    this.indexSelected = this.answer.answer_order;
    //this.checkEditPrompt();
  },
  mounted() {
    setTimeout(
      function () {
        this.initFroala();
      }.bind(this),
      1000
    );
  },
  mixins: [translationMixin],
};
</script>

<template>
  <div>
    <div
      v-if="selectedTab !== 1 && openedOptions && !question_id"
      class="selector-gpt"
    >
      <button
        :class="buttonSelected === 0 ? 'gpt-type-selected' : ''"
        :disabled="askingGpt"
        @click="selectButton(0)"
      >
        {{ langFilter("evaluateProject") }}
      </button>
      <button
        :class="buttonSelected === 2 && 'gpt-type-selected'"
        :disabled="askingGpt"
        @click="selectButton(2)"
      >
        {{ langFilter("evaluatorChat") }}
      </button>
    </div>

    <div v-if="preButtonSelected === 0">
      <div style="width: 100%" class="gpt-project-chat">
        <div ref="scrollContainer">
          <div v-if="!loadingUpdatingPrompt" class="user-message-div row">
            <div class="col-11">
              <textarea
                @change="changeUpdatedPrompt($event)"
                class="solution-user-msg-textarea"
                :value="updatedPrompt"
                maxlength="4096"
              />
            </div>
            <div class="col-1 sol-alignd">
              <button @click="sendQuestionPrompt()">
                <img :src="Send" />
              </button>
            </div>
          </div>
          <div v-else>
            <div class="loading-prompt-question">
              <img :src="require('@/assets/img/general/carga.gif')" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="gpt-project-chat-container">
        <div
          v-if="showConfirmationModal"
          @click="showConfirmationModal = false"
          class="confirmation-modal-chat-fade"
        />
        <div v-if="showConfirmationModal" class="confirmation-modal-chat">
          <h4>¿Estás seguro que quieres eliminar el contenido del chat?</h4>
          <h5>Esta acción no se puede deshacer</h5>
          <div>
            <button @click="showConfirmationModal = false">Cancelar</button>
            <button @click="confirmRemoveChat()">Confirmar</button>
          </div>
        </div>
        <div class="gpt-project-chat">
          <div ref="scrollContainer" class="gpt-help-div-content">
            <!--
          <img
            v-if="showProjectChat && !openedOptions"
            @click="
              openedOptions = !openedOptions;
              buttonSelected = 2;
            "
            :src="ChatbotLogo"
            class="chatBotLogoOption"
            alt="ChatBotLogo"
          />
            -->
            <img
              v-if="showProjectChat"
              @click="showConfirmationModal = true"
              class="gpt-reload-logo"
              :src="Reload"
              alt="ReloadGpt"
            />
            <div
              v-if="question && !showProjectChat && !showQuestionChat"
              class="user-message-div"
            >
              <div v-if="!loading" class="user-message-div row">
                <div class="col-11">
                  <textarea
                    @change="changeUpdatedPrompt($event)"
                    class="solution-user-msg-textarea"
                    :value="question"
                    maxlength="4096"
                  />
                </div>
                <div class="col-1 sol-alignd">
                  <button @click="sendQuestionPrompt()">
                    <img :src="Send" />
                  </button>
                </div>
              </div>
              <div v-else>
                <div class="loading-gpt">
                  <img :src="require('@/assets/img/general/carga.gif')" />
                </div>
              </div>
            </div>
            <div
              v-if="
                (!question && !loading && !alreadyAsked) ||
                showProjectChat ||
                showQuestionChat
              "
              class="bot-message-div"
            >
              Hola, soy tu asistente de IA entrenado para este proyecto, ¿en qué
              puedo ayudarte?
            </div>
            <div v-for="(message, i) in messages" :key="i">
              <div
                v-if="message.message && message.message != ''"
                class="user-message-div row"
              >
                <div class="col-12">
                  <textarea
                    v-if="editing === i"
                    @change="changeUpdatedPrompt($event)"
                    class="solution-user-msg-textarea"
                    :value="updatedPrompt"
                    maxlength="4096"
                  />
                  <span style="white-space: pre-line" v-else>{{
                    message.message
                  }}</span>
                </div>
                <!-- <div class="col-1 sol-alignd">
              <button v-if="editing !== i" @click="setEditing(i)">
                <img :src="require('@/assets/img/projects/edit.svg')" />
              </button>
              <button v-else @click="saveEditing()">
                <img :src="Send" />
              </button>
            </div> -->
              </div>

              <div
                v-for="(file, fileI) in message.files"
                :key="fileI"
                style="justify-content: flex-end"
                class="row"
              >
                <div class="col-5">
                  <div v-if="file.deleted == 0" class="file-uploaded-gpt-div">
                    <img :src="FileGptIcon" alt="FileGptIcon" />
                    <a
                      :href="getFilePath(file.file_name, file.file_path)"
                      target="_blank"
                    >
                      <p>{{ file.file_name }}</p>
                    </a>
                  </div>
                  <div v-else class="file-uploaded-gpt-div">
                    <img :src="FileGptIcon" alt="FileGptIcon" />
                    <p class="default-cursor">
                      Se ha eliminado el documento {{ file.file_name }}.
                    </p>
                  </div>
                </div>
              </div>
              <div class="bot-message-div row">
                <div class="col-1 sol-alignd">
                  <button @click="copyContent(message.response)">
                    <img :src="Copy" />
                  </button>
                </div>
                <div class="col-11">
                  <div v-html="processedResponse(message.response)" />
                </div>
              </div>
            </div>
            <div
              v-if="
                selectedTab === 2 &&
                buttonSelected === 0 &&
                !preButtonSelected &&
                !alreadyEvaluated
              "
            >
              <div style="width: 100%">
                <div ref="scrollContainer">
                  <div
                    v-if="!loadingUpdatingPrompt"
                    class="user-message-div row"
                  >
                    <div class="col-11">
                      <textarea
                        @change="evaluatorPrompt = $event.target.value"
                        class="solution-user-msg-textarea"
                        :value="langFilter(evaluatorPrompt)"
                        maxlength="4096"
                      />
                    </div>
                    <div class="col-1 sol-alignd">
                      <button @click="evaluateProject()">
                        <img :src="Send" />
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <div class="loading-prompt-question">
                      <img :src="require('@/assets/img/general/carga.gif')" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="
                selectedTab === 2 &&
                buttonSelected === 0 &&
                !preButtonSelected &&
                alreadyEvaluated &&
                loading
              "
              class="user-message-div row"
            >
              <div class="col-11">
                <span>{{ langFilter(evaluatorPrompt) }}</span>
              </div>
            </div>
            <div
              v-if="loading && sendingMessage !== ''"
              class="user-message-div row"
            >
              <div class="col-12">
                <span style="white-space: pre-line">{{ sendingMessage }}</span>
              </div>
            </div>

            <div v-if="loading" class="bot-message-div row">
              <div class="loading-gpt">
                <img :src="require('@/assets/img/general/carga.gif')" />
              </div>
            </div>
          </div>

          <div class="row">
            <div v-for="(file, fileI) in files" :key="fileI" class="col">
              <div class="file-uploaded-gpt-div">
                <img :src="FileGptIcon" alt="FileGptIcon" />
                <a :href="file.url" target="_blank">
                  <p>{{ file.title }}</p>
                </a>
                <h5 @click="removeFile(fileI)">x</h5>
              </div>
            </div>
          </div>
          <div>
            <input
              @change="uploadFIle($event)"
              ref="fileInput"
              style="display: none"
              type="file"
              accept=".c,.cs,.cpp,.csv,.doc,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt,.css,.jpeg,.jpg,.js,.gif,.png,.sh,.ts,.xlsx"
              multiple
            />
            <form @submit="sendGpt($event)" class="gpt-project-input row">
              <div class="col-1">
                <img @click="openFile" :src="FileIcon" alt="file" />
              </div>
              <div class="col-10">
                <textarea
                  @keydown="handleKeyDown"
                  @input="handleInput"
                  type="text"
                  placeholder="Escribe tu mensaje..."
                  v-model="prompt"
                  maxlength="4096"
                />
              </div>
              <div class="col-1">
                <img
                  v-if="loading"
                  @click="stopGpt($event)"
                  :src="Stop"
                  alt="stop"
                />
                <img v-else @click="sendGpt($event)" :src="Send" alt="send" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import Send from "./images/send.png";
import FileIcon from "./images/file.svg";
import FileGptIcon from "./images/file-gpt-icon.png";
import Reload from "../solution/images/reload.png";
import Copy from "./images/copy.png";
import Stop from "./images/stop.webp";
import MarkdownIt from "markdown-it";
import axios from "axios";
import DOMPurify from "dompurify";
import ChatbotLogo from "./images/chatbot.svg";

export default {
  props: [
    "lang",
    "space_id",
    "project_id",
    "question_id",
    "showAllChat",
    "showQuestionChat",
    "selectedTab",
    "preButtonSelected",
  ],
  data() {
    return {
      md: new MarkdownIt(),
      ChatbotLogo: ChatbotLogo,
      Send: Send,
      FileIcon: FileIcon,
      FileGptIcon: FileGptIcon,
      Reload: Reload,
      Copy: Copy,
      prompt: "",
      showProjectChat: true,
      loading: false,
      files: [],
      editing: -1,
      updatedPrompt: "",
      showConfirmationModal: false,
      question: null,
      uploadedFiles: [],
      messages: [],
      sendingMessage: "",
      Stop: Stop,
      alreadyAsked: false,
      indexAsked: -1,
      message_id: 0,
      old_message_id: 0,
      buttonSelected: 2,
      openedOptions: true,
      loadingUpdatingPrompt: false,
      evaluatorPrompt: "evaluateProject",
      alreadyEvaluated: false,
      askingGpt: false,
      traducciones: [
        {
          name: "evaluateProject",
          es: "Evaluar Proyecto",
          en: "Evaluate Project",
        },
        {
          name: "currentQuestion",
          es: "Pregunta Actual",
          en: "Current Question",
        },
        {
          name: "evaluatorChat",
          es: "Chat del Evaluador",
          en: "Evaluator Chat",
        },
      ],
    };
  },
  computed: {
    processedResponse() {
      return (response) => {
        if (!response) return "";
        const sanitizedContent = DOMPurify.sanitize(response);
        const regex = /<\/?(h1|h2|h3|h4|h5)>/gi;
        return sanitizedContent.replace(regex, (match) => {
          return match.replace(/h1|h2|h3|h4|h5/, "h6");
        });
      };
    },
  },

  async created() {
    await this.getProjectGptChat();
    if (this.question_id) {
      this.showProjectChat = false;
      // * Shoots the question prompt
      //   if (
      //     this.messages.length === 0 &&
      //     this.updatedPrompt !== "" &&
      //     this.preButtonSelected === 1
      //   ) {
      //     await this.sendQuestionPrompt();
      //   }
    }
    this.getProjectGptChat();
  },
  mounted() {
    document.addEventListener("copy", this.handleCopy);
  },
  beforeUnmount() {
    document.removeEventListener("copy", this.handleCopy);
  },
  methods: {
    evaluateProject() {
      const prompt = this.langFilter(this.evaluatorPrompt);
      this.loading = true;
      this.alreadyEvaluated = true;
      this.askingGpt = true;
      this.$emit("askingGpt", true);
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${2}`,
          {
            project_id: this.project_id,
            message: prompt,
            question_id: null,
            assistant_type: 2,
          }
        )
        .finally(() => {
          this.loading = false;
          this.getProjectGptChat();
          this.evaluatorPrompt = "evaluateProject";
          this.askingGpt = false;
          this.$emit("askingGpt", false);
        });
    },
    getFilePath(file_name, file_path) {
      return `${process.env.VUE_APP_API_STORAGE}/storage/${file_path}`;
    },
    confirmRemoveChat() {
      this.showConfirmationModal = false;
      this.removeChat();
    },

    setEditing(i) {
      if (this.editing > 0) {
        alert("You are already editing a message");
      } else {
        this.editing = i;
        this.updatedPrompt = this.messages[i].message;
      }
    },
    changeUpdatedPrompt(e) {
      this.updatedPrompt = e.target.value;
    },
    handleInput(event) {
      event.target.style.height = "25px";
      event.target.style.height = `${event.target.scrollHeight}px`;
    },
    handleKeyDown(event) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          // Increase the size of the textarea
          event.target.style.height = `${event.target.scrollHeight + 20}px`;
        } else {
          // Prevent the default action (inserting a newline)
          event.preventDefault();
          // Call your function to send the form
          event.target.style.height = "25px";
          this.sendGpt(event);
        }
      }
    },

    selectButton(button) {
      if (!this.askingGpt) {
        this.buttonSelected = button;
        this.alreadyEvaluated = false;
        this.scrollToBottom();
      }
    },

    saveEditing() {
      this.loading = true;
      this.editing = -1;
      const prompt =
        this.updatedPrompt !== "" ? this.updatedPrompt : this.question;
      //   this.previousUserMessages.push(this.updatedPrompt);
      this.scrollToBottom();
      this.updatedPrompt = "";

      let type = this.preButtonSelected
        ? this.preButtonSelected
        : this.selectedTab > 0
        ? this.selectedTab
        : 1;
      // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
      if (this.question_id) {
        type = 1;
      }
      this.askingGpt = true;
      this.$emit("askingGpt", true);
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
          {
            project_id: this.project_id,
            message: prompt,
            assistant_type: type,
          }
        )
        .then(() => {
          this.getProjectGptChat();
          this.loading = false;
          this.scrollToBottom();
        })
        .catch((error) => {
          console.error(error);
          alert("Error editando el mensaje");
        })
        .finally(() => {
          this.askingGpt = false;
          this.$emit("askingGpt", false);
        });
    },

    handleCopy(event) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const divs = document.querySelectorAll(
          ".user-message-div, .bot-message-div"
        );
        for (let div of divs) {
          if (range.intersectsNode(div)) {
            event.preventDefault();
            const parser = new DOMParser();
            const doc = parser.parseFromString(
              range.cloneContents().textContent,
              "text/html"
            );
            let text = doc.body.textContent || "";

            // Remove extra spaces while preserving necessary spaces and newlines
            text = text
              .split("\n")
              .map((line) => line.trim().replace(/\s+/g, " ")) // Trim lines and replace multiple spaces with a single space
              .filter((line) => line.length > 0) // Remove empty lines
              .join("\n"); // Join lines with a newline character

            event.clipboardData.setData("text/plain", text);
            break;
          }
        }
      }
    },

    copyContent(responseContent) {
      if (!navigator.clipboard) {
        console.error("Clipboard API is not available");
        return;
      }
      // Extracting text content from HTML
      const parser = new DOMParser();
      const doc = parser.parseFromString(responseContent, "text/html");
      let text = doc.body.textContent || "";

      // Remove extra spaces while preserving necessary spaces and newlines
      text = text
        .split("\n")
        .map((line) => line.trim().replace(/\s+/g, " ")) // Trim lines and replace multiple spaces with a single space
        .filter((line) => line.length > 0) // Remove empty lines
        .join("\n"); // Join lines with a newline character

      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    },
    openFile() {
      this.$refs.fileInput.click();
    },
    uploadFIle(e) {
      const files = e.target.files;
      const allowedExtensions = [
        ".c",
        ".cs",
        ".cpp",
        ".csv",
        ".doc",
        ".docx",
        ".html",
        ".java",
        ".json",
        ".md",
        ".pdf",
        ".php",
        ".pptx",
        ".py",
        ".rb",
        ".tex",
        ".txt",
        ".css",
        ".jpeg",
        ".jpg",
        ".js",
        ".gif",
        ".png",
        ".sh",
        ".ts",
        ".xlsx",
      ];

      for (let file of files) {
        const fileExtension = "." + file.name.split(".").pop();

        if (!allowedExtensions.includes(fileExtension)) {
          alert(
            "Invalid file type. Only .c, .cs, .cpp, .csv, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .sh, .ts files are allowed."
          );
          return;
        }

        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].title === file.name) {
            return;
          }
        }

        this.files.push({
          title: file.name,
          url: URL.createObjectURL(file),
          type: file.type,
          file: file,
        });
      }
    },
    removeFile(i) {
      this.files.splice(i, 1);
    },
    removeChat() {
      let type = this.preButtonSelected
        ? this.preButtonSelected
        : this.selectedTab > 0
        ? this.selectedTab
        : 1;

      // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
      if (this.question_id) {
        type = 1;
      }

      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/removeProjectChatGpt/${this.project_id}/${type}`
        )
        .then(() => {
          this.messages = [];
          this.$emit("editPrompt");
        })
        .catch((error) => {
          console.error(error);
          alert("Error eliminando el chat del Asistente");
        });
    },
    stopGpt(e) {
      e.preventDefault();
      this.loading = false;
      this.sendingMessage = "";
      this.showProjectChat = true;
      this.files = [];
      this.prompt = "";
      this.scrollToBottom();
    },
    sendGpt(e) {
      e.preventDefault();
      const maxFiles = 5;
      const maxSize = 8388608;
      let totalSize = 0;

      this.files.forEach((file) => {
        totalSize += file.file.size;
      });

      if (this.files.length > maxFiles) {
        alert(`You can only upload a maximum of ${maxFiles} files.`);
        return;
      }

      if (totalSize > maxSize) {
        alert(
          `Total file size should not exceed ${maxSize / (1024 * 1024)} MB.`
        );
        return;
      }

      if (this.files.length === 0 && this.prompt === "") return;

      if (this.files.length > 0) {
        const forbiddenTypes = [
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];

        for (let file of this.files) {
          if (forbiddenTypes.includes(file.type)) {
            alert("PowerPoint files are not allowed");
            return;
          }
        }
      }

      this.loading = true;
      const prompt = this.prompt;
      this.sendingMessage = prompt;
      this.prompt = "";
      //   this.previousUserMessages.push(prompt);
      this.scrollToBottom();

      let formData = new FormData();
      formData.append(
        "project_id",
        this.project_id === undefined ? null : this.project_id
      );

      this.files.forEach((file, index) => {
        formData.append("files[" + index + "]", file.file);
      });

      let type = this.preButtonSelected
        ? this.preButtonSelected
        : this.selectedTab > 0
        ? this.selectedTab
        : 1;

      if (this.question_id) {
        type = 1;
      }

      formData.append("assistant_type", type);

      formData.append("message", prompt);
      formData.append(
        "question_id",
        this.question_id === undefined ? null : this.question_id
      );
      formData.append(
        "message_id",
        this.message_id === 0 ? null : this.message_id
      );

      if (this.files.length > 0) {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/assistant/uploadFile`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            this.$refs.fileInput.value = "";
            if (this.question) {
              this.previousUserMessages.push(prompt);
              const response = res.data.message;
              this.previousBotMessages.push(response);
            } else {
              this.getProjectGptChat();
            }
            this.loading = false;
            this.scrollToBottom();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        let type = this.preButtonSelected
          ? this.preButtonSelected
          : this.selectedTab > 0
          ? this.selectedTab
          : 1;

        // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
        let question_id = this.question_id ? this.question_id : null;
        if (this.question_id) {
          if (type == 2) {
            question_id = null;
          }
          type = 1;
        }
        this.askingGpt = true;
        this.$emit("askingGpt", true);
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
            {
              project_id: this.project_id,
              message: prompt,
              question_id: question_id,
              assistant_type: type,
            }
          )
          .then(async () => {
            if (this.showQuestionChat) {
              await this.getCurrentQuestionGptChat();
            } else {
              await this.getProjectGptChat();
            }
            this.loading = false;
            this.scrollToBottom();
          })
          .catch((error) => {
            alert("Error enviando mensaje al Asistente");
            console.error(error);
          })
          .finally(() => {
            this.askingGpt = false;
            this.$emit("askingGpt", false);
          });
      }
      this.files = [];
    },
    setPrompt(e) {
      this.prompt = e.target.value;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.scrollContainer.scrollTop =
          this.$refs.scrollContainer.scrollHeight;
      });
    },

    callCompleteChat() {
      let type = this.preButtonSelected
        ? this.preButtonSelected
        : this.selectedTab > 0
        ? this.selectedTab
        : 1;
      // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
      if (this.question_id) {
        type = 1;
      }
      this.askingGpt = true;
      this.$emit("askingGpt", true);
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
          {
            project_id: this.project_id,
            assistant_type: type,
            message:
              this.previousUserMessages[this.previousUserMessages.length - 1],
          }
        )
        .then((res) => {
          const response = res.data.message;
          this.previousBotMessages.push(response);
          this.searchingGpt = false;
        })
        .catch((error) => {
          alert("Error enviando mensaje al Asistente");
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
          this.scrollToBottom();
          this.askingGpt = false;
          this.$emit("askingGpt", false);
        });
    },

    async getProjectGptChat() {
      try {
        const type = this.preButtonSelected
          ? this.preButtonSelected
          : this.selectedTab > 0
          ? this.selectedTab
          : 1;
        this.askingGpt = true;
        this.$emit("askingGpt", true);
        let res;
        if (this.question_id) {
          res = await axios.get(
            `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}/${this.question_id}`
          );
        } else {
          res = await axios.get(
            `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`
          );
        }

        this.askingGpt = false;
        this.$emit("askingGpt", false);

        this.updatedPrompt = res.data.question_prompt
          ? res.data.question_prompt
          : "";
        this.messages = res.data.messages;
        this.sendingMessage = "";
        this.scrollToBottom();
        if (this.alreadyAsked) {
          this.$emit("reloadEditPrompt");
        }
      } catch (error) {
        console.error(error);
        alert("Error obteniendo el chat del Asistente");
      }
    },

    async getQuestionGptChat(type) {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/getProjectUserChatGpt/${this.project_id}/${this.question_id}/${type}`
        )
        .then((res) => {
          if (res.data) {
            this.alreadyAsked = true;
            if (this.message_id === 0) this.message_id = res.data;
            this.getCurrentQuestionGptChat();
          } else {
            axios
              .get(
                `${process.env.VUE_APP_API_URL}/getQuestionPrompt/${this.question_id}`
              )
              .then((res) => {
                this.question = res.data.gpt_prompt;
                return res.data.gpt_prompt;
              })
              .catch((error) => {
                console.error(error);
                alert("Error getting the question prompt");
                return null;
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getCurrentQuestionGptChat() {
      try {
        this.askingGpt = true;
        this.$emit("askingGpt", true);
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/1/${this.question_id}`
        );
        this.messages = res.data.messages;
        this.sendingMessage = "";
        this.scrollToBottom();
        this.askingGpt = false;
        this.$emit("askingGpt", false);
      } catch (error) {
        console.error(error);
        alert("Error obteniendo el chat del Asistente");
        this.askingGpt = false;
        this.$emit("askingGpt", false);
      }
    },

    async sendQuestionPrompt() {
      this.loadingUpdatingPrompt = true;
      const prompt =
        this.updatedPrompt !== "" ? this.updatedPrompt : this.question;
      this.loading = true;

      let type = this.preButtonSelected
        ? this.preButtonSelected
        : this.selectedTab > 0
        ? this.selectedTab
        : 1;
      // si el usuario esta en la pestaña de preguntas el tipo es 1 siempre
      if (this.question_id) {
        type = 1;
      }
      this.askingGpt = true;
      this.$emit("askingGpt", true);
      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/projects/${this.project_id}/${type}`,
          {
            project_id: this.project_id,
            assistant_type: this.selectedTab,
            message: prompt,
            question_id: this.question_id,
          }
        )
        .then(async () => {
          await axios
            .post(`${process.env.VUE_APP_API_URL}/ProjectUserGptMessage`, {
              project_id: this.project_id,
              question_id: this.question_id,
            })
            .then((res) => {
              this.message_id = res.data;
            });
          await this.getCurrentQuestionGptChat();
          this.loading = false;
          this.alreadyAsked = true;
          this.$emit("reloadEditPrompt");
          this.$emit("setEditedPrompt");
          this.scrollToBottom();
        })
        .catch((error) => {
          console.error(error);
          alert("Error enviando mensaje al Asistente");
        })
        .finally(() => {
          this.question = null;
          this.updatedPrompt = "";
          this.loadingUpdatingPrompt = false;
          this.askingGpt = false;
          this.$emit("askingGpt", false);
        });
    },
  },
  watch: {
    selectedTab(newVal) {
      this.getProjectGptChat(newVal);
    },
    showAllChat(newVal) {
      this.loading = true;
      this.messages = [];
      if (newVal) {
        this.old_message_id = this.message_id;
        this.message_id = 0;
        this.showProjectChat = true;
        this.getProjectGptChat();
      }
      //    else {
      //     (async () => {
      //       this.showProjectChat = false;
      //       this.message_id = this.old_message_id;
      //       if (this.alreadyAsked) {
      //         await this.getProjectGptChat();
      //       }
      //     })();
      //   }
      this.loading = false;
    },
    showQuestionChat(newVal) {
      this.loading = true;
      this.messages = [];
      if (newVal) {
        this.showProjectChat = false;
        this.getCurrentQuestionGptChat();
      }
      // else {
      //     this.showProjectChat = true;
      //     this.getProjectGptChat();
      //   }
      this.loading = false;
    },
  },
  mixins: [translationMixin],
};
</script>

<template>
  <div class="solution-first-container">
    <div
      v-if="!cdti"
      :class="[
        { 'solution-answer-map-opened': mapOpened },
        ,
        'solution-header-div',
      ]"
    >
      <div class="row">
        <div class="col-12 align-left">
          <h4
            v-if="firstSolutionInStation"
            style="font-size: 26px; margin-bottom: 25px"
            class="title-station"
          >
            {{ station_name }}
          </h4>
          <p class="title-station">{{ solution_name }}</p>
        </div>
      </div>
    </div>
    <!-- @click="repositionMap(answerIndex)" -->
    <div v-if="answers.length > 0">
      <solution-write
        @editedSolution="editedSolution($event)"
        @errorFileUpload="errorFileUpload"
        @reloadPercentage="reloadPercentage"
        @updateSendToCorrect="updateSendToCorrect"
        @prepareAnswers="prepareAnswers($event)"
        @reloadChatGpt="reloadChatGpt()"
        v-for="(answer, answerIndex) in answers"
        :answers="answers"
        :answer="answer"
        :loadedPrev="loadedPrev"
        :key="answerIndex"
        :role="role"
        :mapOpened="mapOpened"
        :edited="edited"
        :answerIndex="answerIndex"
        :answerIndex2="answerIndex2"
        :project_id="project_id"
        :solution_id="mySolution"
        :lang="lang"
        :cdti="cdti"
        :space_id="space_id"
      />
    </div>
    <div v-else-if="!loading">
      <div v-if="!checkIfLengthIs0()" class="div-101">
        <h4 class="solution-no-results">
          {{ langFilter("noResults") }}
        </h4>
      </div>
    </div>
    <div
      v-if="!$parent.initialLoadState"
      :class="[{ 'solution-answer-map-opened': mapOpened }, , 'div-center']"
    >
      <div class="solutions-loading-gif-div" v-if="!cdti">
        <img
          class="solutions-loading-gif-container"
          :src="require('@/assets/img/general/loading.gif')"
        />
      </div>
    </div>
    <div class="solution-component-footer" />
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import SolutionWrite from "./SolutionWriteComponent.vue";
export default {
  components: {
    SolutionWrite,
  },
  props: [
    "answers",
    "mapOpened",
    "project_id",
    "loading",
    "role",
    "lang",
    "currentIndex",
    "loadingMoreSolutions",
    "answerIndex2",
    "solution_name",
    "loadedPrev",
    "cdti",
    "space_id",
    "allStations",
    "allSolutions",
    "currentSolutions",
    "station_id",
  ],
  data() {
    return {
      edited: false,
      prevIndex: 0,
      currentSolution: "",
      loadingMore: false,
      station_name: "",
      firstSolutionInStation: false,
      traducciones: [
        {
          name: 1,
          es: "Pendiente",
          en: "Pending",
        },
        {
          name: "noResults",
          es: "No hay preguntas asociadas a este apartado",
          en: "There are no questions in this section",
        },
      ],
    };
  },
  methods: {
    reloadChatGpt() {
      this.$emit("reloadChatGpt");
    },
    restoreScrollPosition() {
      //get element by id
      const height = document.getElementById("scrollContainer-0").offsetHeight;
      window.scrollTo({
        top: height,
        left: 0,
        behavior: "instant",
      });
    },
    checkIfLengthIs0() {
      if (this.answers != undefined) {
        if (this.answers.length > 0) {
          return true;
        }
      }
      return false;
    },
    scrollMap() {
      try {
        document
          .getElementById(`solution-border${this.currentSolution[0]}`)
          .scrollIntoView(true);
      } catch (e) {
        console.log(e);
      }
    },
    repositionMap(answerIndex) {
      this.currentSolution = [
        this.answers[0].solution_id,
        this.answers[answerIndex].question_id,
      ];
      this.scrollMap();
      this.$emit("repositionMap", this.currentSolution);
    },
    reloadPercentage() {
      this.$emit("reloadPercentage");
    },
    updateSendToCorrect() {
      this.$emit("updateSendToCorrect");
    },
    sendCorrection() {
      this.$emit("sendCorrection");
    },
    editedSolution(data) {
      this.$emit("editedSolution", data);
    },
    errorFileUpload() {
      this.$emit("errorFileUpload");
    },
    prepareAnswers(data) {
      this.$emit("prepareAnswers", data);
    },
  },
  created() {
    if (this.answers.length > 0) {
      this.currentSolution = this.answers[0].solution_id;
      this.mySolution = this.answers[0].solution_id;
    }
  },
  mounted() {
    // Check if allSolutions is defined and is an array before proceeding
    if (Array.isArray(this.allSolutions) && this.allSolutions.length > 0) {
      // Proceed with grouping solutions by station_id
      const agrupados = this.allSolutions.reduce((resultado, solution) => {
        if (!resultado[solution.station_id]) {
          resultado[solution.station_id] = [];
        }
        resultado[solution.station_id].push(solution);
        return resultado;
      }, {});

      const solutionByStation = Object.values(agrupados);

      if (this.currentSolution) {
        const subArreglo = solutionByStation.find((sub) =>
          sub.some((objeto) => objeto.id === this.currentSolution)
        );

        if (subArreglo) {
          const posicion = subArreglo.findIndex(
            (objeto) => objeto.id === this.currentSolution
          );
          this.firstSolutionInStation = posicion === 0;
          this.station_name =
            this.allStations.find(
              (station) => station.id === subArreglo[posicion].station_id
            )?.name || "";
        }
      }
    } else {
      // Handle the case where allSolutions is not available or empty
      console.error("allSolutions is not defined or is empty.");
    }

    // Perform additional actions
    if (!this.cdti) {
      this.scrollMap();
    }

    // Wait 1 second to restore scroll position
    setTimeout(() => {
      this.$parent.initialLoadState = true;
    }, 1000);

    if (this.$parent.checkIfScrollTop()) {
      this.restoreScrollPosition();
    }
  },
  mixins: [translationMixin],
};
</script>

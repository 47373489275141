<style scoped>
@import "../../assets/css/assistants/assistants.css";
</style>

<template>
  <div class="mx-5 px-5 my-4">
    <div class="d-flex">
      <router-link to="/assistants">
        <img :src="require('@/assets/img/general/new_back.svg')" alt="Back" />
      </router-link>
      <header-general
        :title="langFilter('edit_assistant')"
        src="/img/icons/icon-robot-wink.svg"
        class="ms-3"
      />
    </div>
    <div v-if="initialLoading">
      <img
        class="loading-rocket"
        :src="require('@/assets/img/general/loading.gif')"
      />
    </div>
    <form
      v-else-if="form"
      @submit.prevent="submitForm"
      class="d-flex flex-column mx-5"
    >
      <div class="row mb-5">
        <div class="col-md-5">
          <label for="assistantType" class="form-label">
            <span
              :class="[
                'd-inline-block  mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong',
                {
                  'opacity-50': !form.type,
                },
              ]"
              style="padding: 0px 10px"
              >1</span
            >
            {{ langFilter("assistant_type") }} *
          </label>
          <input
            id="assistantType"
            :value="langFilter(form.assistant_type.name)"
            class="form-control"
            disabled
          />
        </div>

        <div class="col-md-7">
          <label for="name" class="form-label">
            <span
              :class="[
                'd-inline-block px-2 mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong',
                {
                  'opacity-50': !form.name,
                },
              ]"
            >
              2
            </span>
            {{ langFilter("assistant_name") }} *
          </label>
          <input
            type="text"
            id="name"
            :placeholder="langFilter('insert_assistant_name')"
            v-model="form.name"
            class="form-control"
            required
          />
        </div>
      </div>

      <div class="mb-5">
        <label for="description" class="form-label">
          <span
            :class="[
              'd-inline-block px-2 mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong',
              {
                'opacity-50': !form.description,
              },
            ]"
            >3</span
          >
          {{ langFilter("description") }} *
        </label>
        <textarea
          id="description"
          v-model="form.description"
          class="form-control"
          :placeholder="langFilter('insert_description')"
          rows="5"
          required
        ></textarea>
      </div>

      <div class="mb-5">
        <label for="instructions" class="form-label">
          <span
            :class="[
              'd-inline-block px-2 mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong',
              {
                'opacity-50': !form.instructions,
              },
            ]"
            >4</span
          >
          {{ langFilter("define_instruction") }} *
        </label>
        <textarea
          id="instructions"
          v-model="form.instructions"
          class="form-control"
          :placeholder="langFilter('insert_instruction')"
          rows="5"
          required
        ></textarea>
      </div>

      <div class="mb-4">
        <span
          class="d-inline-block px-2 mx-2 rounded-circle text-white text-center fw-bold bg-purpleStrong mb-3"
          >5</span
        >
        <div
          type="button"
          :class="[
            'd-inline-block rounded-pill px-3 py-1 ms-3 text-white bg-purpleStrong',
            { 'opacity-50': files.length >= maxFiles },
          ]"
          @click="triggerFileInput"
          :disabled="files.length >= maxFiles"
          :style="{ pointerEvents: files.length >= maxFiles ? 'none' : 'auto' }"
        >
          {{ langFilter("select_files") }}
        </div>
        <input
          type="file"
          ref="fileInput"
          @change="handleFileUpload"
          class="d-none"
          :disabled="isMaxFilesReached"
          multiple
          accept=".c, .cs, .cpp, .csv, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .sh, .ts, .xlsx"
        />
        <p v-if="files.length === 0" class="text-muted fst-italic">
          Puedes adjuntar hasta {{ maxFiles }} archivos.
        </p>
        <p v-else-if="files.length < maxFiles">
          Tienes {{ files.length }} archivo{{
            files.length === 1 ? "" : "s"
          }}
          adjunto{{ files.length === 1 ? "" : "s" }}. Puedes subir
          {{ maxFiles - files.length }} archivo{{
            maxFiles - files.length === 1 ? "" : "s"
          }}
          más.
        </p>
        <p v-else>
          Ya has llegado al máximo de archivos adjuntos. Posees
          {{ maxFiles }} archivos adjuntos.
        </p>
        <ul class="list-group mt-3 mx-3">
          <li
            v-for="(file, index) in files"
            :key="index"
            :class="[
              'd-flex justify-content-between align-items-center px-5 py-2 my-1',
              {
                ' bg-hover-file shadow-sm': hoverFile === index,
              },
            ]"
            @mouseover="hoverFile = index"
            @mouseleave="hoverFile = null"
          >
            <div>
              <img
                src="../../assets/img/track/file-assistant.svg"
                class="pb-1"
              />
              {{ file.file_name || file.name }}
            </div>
            <img
              width="20"
              @click="removeFile(index)"
              class="pointer"
              :src="require('@/assets/img/projects/delete.svg')"
            />
          </li>
        </ul>
      </div>

      <div
        v-if="!saveLoading"
        type="submit"
        :class="[
          'd-inline-block rounded-pill px-3 py-1 ms-auto text-white bg-purpleStrong',
          {
            'opacity-50': !isFormValid,
          },
        ]"
        :style="{ pointerEvents: isFormValid ? 'auto' : 'none' }"
        @click="isFormValid ? submitForm() : null"
      >
        {{ langFilter("save_assistant") }}
      </div>
      <span
        v-else
        class="spinner-border ms-auto text-primary"
        role="status"
        style="margin-right: 30px"
      />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "@/mixins/translationMixin";
import translations from "./translate/AssistantTranslate.json";

export default {
  name: "EditAssistant",
  props: ["lang"],
  data() {
    return {
      initialLoading: false,
      saveLoading: false,
      hoverFile: null,
      form: null,
      deletedFiles: [],
      maxFiles: 20,
      traducciones: translations,
    };
  },
  computed: {
    files() {
      return Array.from(this.form.files);
    },
    isFormValid() {
      return this.form.name && this.form.description && this.form.instructions;
    },
    isMaxFilesReached() {
      return this.files.length >= this.maxFiles;
    },
  },
  methods: {
    triggerFileInput() {
      if (!this.isMaxFilesReached) {
        this.$refs.fileInput.click();
      }
    },
    isValidFileExtension(fileName) {
      const allowedExtensions = [
        ".c",
        ".cs",
        ".cpp",
        ".csv",
        ".doc",
        ".docx",
        ".html",
        ".java",
        ".json",
        ".md",
        ".pdf",
        ".php",
        ".pptx",
        ".py",
        ".rb",
        ".tex",
        ".txt",
        ".css",
        ".jpeg",
        ".jpg",
        ".js",
        ".gif",
        ".png",
        ".sh",
        ".ts",
        ".xlsx",
      ];

      const extension = fileName
        .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
        .toLowerCase();
      return allowedExtensions.includes(`.${extension}`);
    },
    handleFileUpload(event) {
      const selectedFiles = Array.from(event.target.files);

      const validFiles = selectedFiles.filter((file) =>
        this.isValidFileExtension(file.name)
      );

      const remainingSlots = this.maxFiles - this.files.length;

      const filesToAdd = validFiles.slice(0, remainingSlots);

      this.form.files = [...this.files, ...filesToAdd];

      this.$refs.fileInput.value = null;
    },
    removeFile(index) {
      const filesArray = Array.from(this.form.files || []);
      const removedFile = filesArray.splice(index, 1)[0];
      this.form.files = filesArray;

      if (removedFile && removedFile.id) {
        this.deletedFiles.push(removedFile.id);
      }
    },
    submitForm() {
      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      formData.append("instructions", this.form.instructions);

      this.files.forEach((file) => {
        formData.append("files[]", file);
      });

      formData.append("deleted_files", JSON.stringify(this.deletedFiles));

      this.saveLoading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/assistants/${this.form.id}`,
          formData
        )
        .then((response) => {
          console.log("Asistente actualizado:", response.data);
          this.$router.push("/assistants");
        })
        .catch((error) => {
          console.error("Error al actualizar asistente:", error);
        })
        .finally(() => (this.saveLoading = false));
    },
  },
  mounted() {
    this.initialLoading = true;
    axios
      .get(`${process.env.VUE_APP_API_URL}/assistants/${this.$route.params.id}`)
      .then((response) => {
        const assistant = response.data;
        this.form = assistant;
      })
      .catch((error) => {
        console.error("Error al obtener datos del asistente:", error);
      })
      .finally(() => {
        this.initialLoading = false;
      });
  },
  mixins: [translationMixin],
};
</script>

<template>
  <div class="newProjectV2-content-Container">
    <div v-if="screen == 0">
      <!-- <div class="import-btn">
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="uploadGpt($event)"
        />
        <button @click="openFileInput">{{ langFilter("upload") }}</button>
      </div> -->
      <div
        class="copyProjects-modals-container modal fade"
        id="modal-copy-number"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div
          class="modal-dialog modal-xl modal-dialog-centered copyProjects-modal-vertical"
        >
          <div
            class="modal-fix modal-content copyProjects-modal-number-projects"
            id="modal-number-of-copies"
          >
            <div class="confirmation-texts">
              <p>{{ langFilter("URL Gestor de tareas") }}:</p>
              <input
                style="
                  position: relative;
                  bottom: 23px;
                  width: 100%;
                  text-align: left;
                  padding: 0 10px;
                "
                type="text"
                id="url-gestor-tareas"
                v-model="taskManager"
              />
              <span
                v-if="errorUrl"
                style="color: red; bottom: 10px; position: relative"
                >{{ langFilter("validUrl") }}</span
              >
            </div>
            <div class="confirmation-buttons">
              <button
                id="cancel-copy-button"
                class="invisible-button"
                @click="cancelCopyModal()"
                data-bs-toggle="modal"
                data-bs-target="#modal-copy-number"
              >
                {{ langFilter("cancel") }}
              </button>
              <button @click="setTaskManager()" id="confirm-copy-button">
                {{ langFilter("continue") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-11">
          <input
            type="text"
            class="newProjectV2-content-ProjectName"
            v-model="projectNameContent"
          />
        </div>
        <div class="col-1">
          <button
            @click.stop="startProjectManager($event)"
            class="sidebar-btn-bg sidebar-new-project"
          >
            <img
              :src="require('@/assets/img/right-sidebar/taskManager.webp')"
            />
          </button>
        </div>
      </div>
      <draggable
        v-model="chapters"
        draggable=".chapter"
        handle=".dragg-me-chapter"
        animation="500"
        ghostClass="ghost"
        chosenClass="chosen"
        :options="{
          animation: 500,
          handle: '.dragg-me-chapter',
          ghostClass: 'ghost',
          chosenClass: 'chosen',
          group: 'chapter',
          itemKey: 'id',
        }"
      >
        <div
          class="newProjectV2-content-ChapterContainer chapter"
          v-for="(chapter, indexChapter) in chapters"
          :key="indexChapter"
          :id="'chapter' + indexChapter"
        >
          <img
            :src="require('@/assets/img/projects/arrow-collapse.svg')"
            @click="minimizeExpand($event, chapter)"
            style="transform: rotate(90deg); cursor: pointer"
          />
          <p class="newProjectV2-content-ChapterNumber">
            {{ langFilter("chapter") }} {{ indexChapter + 1 }}.
          </p>
          <input
            :disabled="space === 22680"
            type="text"
            v-model="chapter.chapter"
            class="newProjectV2-content-ChapterName"
            :id="'newProject-Chapter' + indexChapter + 'Name'"
            :placeholder="langFilter('chapterPlaceholder')"
          />
          <table v-if="space !== 22680" class="newProjectV2-content-options">
            <td>
              <img
                :src="require('@/assets/img/projects/drag_icon.png')"
                class="newProjectV2-content-drag dragg-me-chapter"
              />
            </td>
            <td>
              <img
                v-if="indexChapter !== 0"
                :src="require('@/assets/img/projects/modal_X.png')"
                @click="modalDeleteChapter(indexChapter, 1)"
                class="newProjectV2-content-drag"
                @mouseenter="hoverIn('deleteChapter' + indexChapter)"
                @mouseleave="hoverOut()"
              />
              <div
                v-if="hover && hoverId == 'deleteChapter' + indexChapter"
                class="hoverGeneral-box-deleteTask"
              >
                <span>{{ langFilter("delete") }}</span>
              </div>
            </td>
          </table>

          <textarea
            :disabled="space === 22680"
            v-model="chapter.description"
            class="newProjectV2-content-ChapterDescription"
            :placeholder="langFilter('descriptionPlaceholder')"
            :id="'newProject-Chapter' + indexChapter + 'Description'"
          ></textarea>
          <div v-show="chapter.hide == false">
            <draggable
              v-model="chapters[indexChapter].parts"
              draggable=".part"
              handle=".dragg-me-part"
              animation="500"
              ghostClass="ghost"
              chosenClass="chosen"
              :options="{
                animation: 500,
                handle: '.dragg-me-part',
                ghostClass: 'ghost',
                chosenClass: 'chosen',
                group: 'part',
                itemKey: 'id',
              }"
            >
              <div
                class="newProjectV2-content-PartContainer part"
                v-for="(part, indexParts) in chapter.parts"
                :key="indexParts"
                :id="'part' + indexParts"
              >
                <img
                  :src="require('@/assets/img/projects/arrow-collapse.svg')"
                  @click="minimizeExpand($event, part)"
                  style="transform: rotate(90deg); cursor: pointer"
                />
                <img
                  v-if="
                    indexParts + 1 == chapter.parts.length && space !== 22680
                  "
                  :src="require('@/assets/img/projects/add-button.svg')"
                  @click="addPart(chapter)"
                  class="newProjectV2-add-part"
                  @mouseenter="hoverIn('addPart' + indexChapter + indexParts)"
                  @mouseleave="hoverOut()"
                />
                <div
                  v-if="
                    hover && hoverId == 'addPart' + indexChapter + indexParts
                  "
                  class="hoverGeneral-box-newProject-Part"
                >
                  <span>{{ langFilter("addPart") }}</span>
                </div>
                <p
                  :class="
                    indexParts + 1 == chapter.parts.length
                      ? 'newProjectV2-content-PartNumber-add'
                      : 'newProjectV2-content-PartNumber'
                  "
                >
                  {{ langFilter("part") }} {{ indexParts + 1 }}.
                </p>
                <input
                  :disabled="space === 22680"
                  type="text"
                  v-model="part.part"
                  class="newProjectV2-content-PartName"
                  :placeholder="langFilter('partPlaceholder')"
                  :id="
                    'newProject-Part' + indexChapter + '-' + indexParts + 'Name'
                  "
                />

                <table
                  v-if="space !== 22680"
                  class="newProjectV2-content-options"
                >
                  <td>
                    <img
                      :src="require('@/assets/img/projects/drag_icon.png')"
                      class="newProjectV2-content-drag dragg-me-part"
                    />
                  </td>
                  <td>
                    <img
                      v-if="indexParts !== 0"
                      :src="require('@/assets/img/projects/modal_X.png')"
                      class="newProjectV2-content-drag"
                      @click="modalDeletePart(indexParts, indexChapter, 2)"
                      @mouseenter="
                        hoverIn('deletePart' + indexParts + indexChapter)
                      "
                      @mouseleave="hoverOut()"
                    />
                    <div
                      v-if="
                        hover &&
                        hoverId == 'deletePart' + indexParts + indexChapter
                      "
                      class="hoverGeneral-box-deleteTask"
                    >
                      <span>{{ langFilter("delete") }}</span>
                    </div>
                  </td>
                </table>
                <div v-show="part.hide == false">
                  <draggable
                    v-model="chapters[indexChapter].parts[indexParts].tasks"
                    draggable=".task"
                    handle=".dragg-me-task"
                    animation="500"
                    ghostClass="ghost"
                    chosenClass="chosen"
                    :options="{
                      animation: 500,
                      handle: '.dragg-me-task',
                      ghostClass: 'ghost',
                      chosenClass: 'chosen',
                      group: 'task',
                      itemKey: 'id',
                    }"
                  >
                    <div
                      class="newProjectV2-content-TaskContainer task"
                      v-for="(task, indexTasks) in part.tasks"
                      :key="indexTasks"
                      :id="'question' + indexTasks"
                    >
                      <p class="newProjectV2-content-TaskNumber">
                        {{ langFilter("task") }} {{ indexTasks + 1 }}.
                      </p>
                      <input
                        :disabled="space === 22680"
                        type="text"
                        v-model="task.task"
                        class="newProjectV2-content-TaskName"
                        readonly
                      />
                      <table
                        v-if="space !== 22680"
                        class="newProjectV2-content-options"
                      >
                        <td>
                          <img
                            :src="require('@/assets/img/projects/edit_btn.svg')"
                            class="newProjectV2-content-drag"
                            @click="
                              editTask(indexTasks, indexParts, indexChapter)
                            "
                            @mouseenter="
                              hoverIn(
                                'editTask' +
                                  indexTasks +
                                  indexParts +
                                  indexChapter
                              )
                            "
                            @mouseleave="hoverOut()"
                          />
                          <div
                            v-if="
                              hover &&
                              hoverId ==
                                'editTask' +
                                  indexTasks +
                                  indexParts +
                                  indexChapter
                            "
                            class="hoverGeneral-box-editTask"
                          >
                            <span>{{ langFilter("edit") }}</span>
                          </div>
                        </td>
                        <td>
                          <img
                            :src="
                              require('@/assets/img/projects/drag_icon.png')
                            "
                            class="newProjectV2-content-drag dragg-me-task"
                          />
                        </td>
                        <td>
                          <img
                            :src="require('@/assets/img/projects/modal_X.png')"
                            class="newProjectV2-content-drag"
                            @click="
                              modalDeleteTask(
                                indexTasks,
                                indexParts,
                                indexChapter,
                                3
                              )
                            "
                            @mouseenter="
                              hoverIn(
                                'deleteTask' +
                                  indexTasks +
                                  indexParts +
                                  indexChapter
                              )
                            "
                            @mouseleave="hoverOut()"
                          />
                          <div
                            v-if="
                              hover &&
                              hoverId ==
                                'deleteTask' +
                                  indexTasks +
                                  indexParts +
                                  indexChapter
                            "
                            class="hoverGeneral-box-deleteTask"
                          >
                            <span>{{ langFilter("delete") }}</span>
                          </div>
                        </td>
                      </table>
                    </div>
                  </draggable>
                  <div v-if="space !== 22680" class="newProject-center-addtask">
                    <img
                      :src="require('@/assets/img/projects/add-button.svg')"
                      class="newProjectV2-add-task"
                      @click="openTasksModal(indexParts, indexChapter)"
                      @mouseenter="
                        hoverIn(
                          'addPart' + indexChapter + indexParts + 'addtask'
                        )
                      "
                      @mouseleave="hoverOut()"
                    />
                    <div
                      v-if="
                        hover &&
                        hoverId ==
                          'addPart' + indexChapter + indexParts + 'addtask'
                      "
                      class="hoverGeneral-box-newProject"
                    >
                      <span>{{ langFilter("addTask") }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
          <div class="newProjectV2-content-separator"></div>
        </div>
      </draggable>
      <div>
        <div v-if="space !== 22680" class="newProject-center-chapter">
          <img
            :src="require('@/assets/img/projects/add-button.svg')"
            class="newProjectV2-add-chapter"
            @click="addChapter()"
            @mouseenter="hoverIn('addChapter')"
            @mouseleave="hoverOut()"
          />
          <div
            v-if="hover && hoverId == 'addChapter'"
            class="hoverGeneral-box-newProject"
          >
            <span>{{ langFilter("addChapter") }}</span>
          </div>
        </div>
      </div>
      <div
        class="newProjectV2-NextButton"
        :class="{ 'nextButton-clickable': true }"
        @click="$parent.stepsFunction(4)"
      >
        <p class="newProject-ButtonText">
          {{ langFilter("create-project") }}
        </p>
      </div>
    </div>
    <div v-if="screen == 2">
      <newprojectsInfo
        @updateSyllabus="updateSyllabus($event)"
        :lang="lang"
        :syllabus="syllabus"
      ></newprojectsInfo>
    </div>
  </div>
  <new-itinerary-bar
    @changeScreen="changeScreen"
    @showResource="loadResources()"
    :options="options"
    :traducciones="traducciones"
    :lang="lang"
    :resourcesPage="1"
  />

  <new-resources-modal
    v-if="showResources"
    @updateResources="updateResources($event)"
    @closeResources="closeResources"
    :lang="lang"
    :themes="chapters"
    :edit="true"
  />
  <confirmation :lang="lang" ref="modalConfirmation"></confirmation>
</template>
<script>
import "../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
// import FroalaEditor from "../../../node_modules/froala-editor/js/froala_editor.pkgd.min.js";
import translationMixin from "../../mixins/translationMixin.js";
import { VueDraggableNext } from "vue-draggable-next";
import { Modal } from "bootstrap";
import axios from "axios";
import $ from "jquery";

export default {
  props: [
    "template",
    "projectName",
    "lang",
    "chaptersSaved",
    "templateIdSaved",
    "syllabus",
    "space",
    "taskManagerUrl",
  ],

  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      toDelete: 1,
      chapters: [],
      chapterDelete: 0,
      partDelete: 0,
      screen: 0,
      templateIdSaved2: "",
      showResources: false,
      projectNameContent: "",
      hover: false,
      hoverId: 0,
      themes: [],
      taskManager: this.taskManagerUrl,
      errorUrl: false,
      traducciones: [
        {
          name: "chapterPlaceholder",
          es: "Escribir nombre del Tema...",
          en: "Write chapter name...",
        },
        {
          name: "upload",
          es: "Importar",
          en: "Import",
        },
        {
          name: "partPlaceholder",
          es: "Escribir nombre del Apartado...",
          en: "Write part name...",
        },
        {
          name: "questionPlaceholder",
          es: "Escribir nombre de la Pregunta...",
          en: "Write Question name...",
        },
        {
          name: "descriptionPlaceholder",
          es: "Descripción...",
          en: "Description...",
        },
        {
          name: "addTheme",
          es: "Añadir tema",
          en: "Add theme",
        },
        {
          name: "content",
          es: "Contenido",
          en: "Content",
        },
        {
          name: "users",
          es: "Usuarios",
          en: "Users",
        },
        {
          name: "resources",
          es: "Recursos",
          en: "Resources",
        },
        {
          name: "info",
          es: "Información",
          en: "Information",
        },
        {
          name: "itinerary",
          es: "Proyecto",
          en: "Project",
        },
        {
          name: "chapter",
          es: "Tema",
          en: "Chapter",
        },
        {
          name: "part",
          es: "Apartado",
          en: "Part",
        },
        {
          name: "task",
          es: "Pregunta",
          en: "Task",
        },
        {
          name: "addTask",
          es: "Añadir pregunta",
          en: "Add task",
        },
        {
          name: "URL Gestor de tareas",
          es: "URL Gestor de tareas",
          en: "URL Task Manager",
        },
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "continue",
          es: "Continuar",
          en: "Continue",
        },
        {
          name: "validUrl",
          es: "Introduce una URL válida ej: https://acceleralia.com",
          en: "Enter a valid URL eg: https://acceleralia.com",
        },
        {
          name: "addPart",
          es: "Añadir apartado",
          en: "Add part",
        },
        {
          name: "addChapter",
          es: "Añadir tema",
          en: "Add chapter",
        },
        {
          name: "create-project",
          es: "Siguiente",
          en: "Next",
        },
        {
          name: "edit",
          es: "Editar",
          en: "Edit",
        },
        {
          name: "delete",
          es: "Eliminar",
          en: "Delete",
        },
      ],
      options: [
        {
          name: "Contenido",
          icon: "/newitinerary/contentBar.svg",
          active: true,
          url: "/newItinerary/content",
        },
        {
          name: "Recursos",
          icon: "/newitinerary/resourcesBar.svg",
          active: false,
          url: "/newItinerary/resources",
        },
        {
          name: "Información",
          icon: "/newitinerary/infoBar.svg",
          active: false,
          url: "/newItinerary/info",
        },
      ],
    };
  },
  created() {
    this.projectNameContent = this.projectName;
    this.templateIdSaved2 = this.templateIdSaved;

    if (this.template.id !== -1) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/getTrack`, {
          track: this.template.id,
        })
        .then((response) => {
          this.chapters = response.data.chapters;
          if (
            this.chaptersSaved.length > 0 &&
            this.templateIdSaved2 == this.template.id
          ) {
            this.chapters = this.chaptersSaved;
          }
          this.chapters.forEach((chapter) => {
            chapter.parts.forEach((part) => {
              let i = 0;
              part.tasks.forEach((task) => {
                task.edited = false;
                i = i + 1;
              });
            });
          });
          this.defaultChapters = response.data.default_chapters;
        });
    } else {
      this.chapters = [];
      if (
        this.chaptersSaved.length > 0 &&
        this.templateIdSaved2 == this.template.id
      ) {
        this.chapters = this.chaptersSaved;
      }
      this.chapters.forEach((chapter) => {
        chapter.parts.forEach((part) => {
          let i = 0;
          part.tasks.forEach((task) => {
            task.edited = false;
            i = i + 1;
          });
        });
      });
    }
  },
  methods: {
    startProjectManager(e) {
      e.preventDefault();
      const myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-number")
      );
      myModal.show();
    },
    cancelCopyModal() {
      this.errorUrl = false;
      const myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-number")
      );
      this.taskManager = this.taskManagerUrl;
      myModal.hide();
    },

    setTaskManager() {
      if (this.taskManager !== "") {
        if (!this.isValidUrl(this.taskManager)) {
          this.errorUrl = true;
          return;
        }
      }

      this.errorUrl = false;
      this.$emit("taskManager", this.taskManager);
      const myModal = Modal.getOrCreateInstance(
        document.getElementById("modal-copy-number")
      );
      myModal.hide();
    },

    isValidUrl(string) {
      try {
        const url = new URL(string);
        const hostnameValidation = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
        if (hostnameValidation.test(url.hostname)) {
          return true;
        }
        return false;
      } catch (_) {
        return false;
      }
    },

    uploadGpt(event) {
      const file = event.target.files[0];

      // Get the value of the clean_project checkbox
      //const cleanProjectCheckbox = document.querySelector("#clean_project");
      //const cleanProject = cleanProjectCheckbox.checked ? "on" : "off";
      const cleanProject = "on";

      // Create a FormData instance to hold the file and other data
      const formData = new FormData();
      formData.append("doc", file);
      formData.append("clean_project", cleanProject);
      formData.append("project_id", this.project.id);
      formData.append("space_id", this.space.id);

      // Make the axios call
      axios
        .post(`${process.env.VUE_APP_API_URL}/newProject/fromFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$emit(
            "showConfirmationModalInfo",
            "Archivo subido correctamente"
          );
        })
        .catch((error) => {
          console.error(error);
          this.$emit(
            "showConfirmationModalInfo",
            "Ha habido un error al subir el archivo. Por favor, inténtalo de nuevo."
          );
        });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    updateResources(data) {
      this.chapters = data;
      this.openResourcesModal();
    },
    openResourcesModal() {
      this.showResources = !this.showResources;
    },
    editTask(task, part, chapter) {
      this.$emit("openEditModal", chapter, part, task);
    },
    openTasksModal(part, chapter) {
      this.$parent.taskEdit = false;
      this.$parent.modalTask = true;
      this.$parent.addTask = {
        task: "",
        type: 1,
        // question: "",
        instructions: "",
        // help: "",
        newQuestionChecks: [],
        radio: 0,
        optional: false,
        chapter: 0,
        part: 0,
        edited: true,
      };

      this.$parent.addTask.part = part;
      this.$parent.addTask.chapter = chapter;
    },
    addChapter() {
      this.chapters.push({
        chapter: "",
        description: "",
        hide: false,
        files: [],
        preResources: [],
        parts: [
          {
            part: "",
            files: [],
            optional: false,
            hide: false,
            preResources: [],
            tasks: [],
          },
        ],
      });
    },
    addPart(chapter) {
      chapter.parts.push({
        part: "",
        files: [],
        optional: false,
        hide: false,
        preResources: [],
        tasks: [],
      });
      this.hoverOut();
    },
    minimizeExpand(el, chapter, part) {
      if (chapter) {
        if (chapter.hide) {
          $(el.target).css("transform", "rotate(90deg)");
        } else {
          $(el.target).css("transform", "rotate(0deg)");
        }
        chapter.hide = !chapter.hide;
      } else if (part) {
        if (part.hide) {
          $(el.target).css("transform", "rotate(90deg)");
        } else {
          $(el.target).css("transform", "rotate(0deg)");
        }
        part.hide = !part.hide;
      }
    },
    modalDeleteChapter(id, indicator) {
      this.chapterDelete = id;
      this.toDelete = indicator;
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.$refs.modalConfirmation.confirmationMessage =
        "¿Está seguro que desea eliminar el tema?";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Texto";
      myModal.toggle();
    },
    modalDeletePart(part, chapter, indicator) {
      this.partDelete = part;
      this.chapterDelete = chapter;
      this.toDelete = indicator;
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.$refs.modalConfirmation.confirmationMessage =
        "¿Está seguro que desea eliminar el apartado?";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Texto";
      myModal.toggle();
    },
    modalDeleteTask(task, part, chapter, indicator) {
      this.taskDelete = task;
      this.partDelete = part;
      this.chapterDelete = chapter;
      this.toDelete = indicator;
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      this.$refs.modalConfirmation.confirmationMessage =
        "¿Está seguro que desea eliminar la tarea?";
      this.$refs.modalConfirmation.confirmationMessage2 = "";
      this.$refs.modalConfirmation.confirmationButtonText = "Cancelar";
      this.$refs.modalConfirmation.confirmationButtonText2 = "Texto";
      myModal.toggle();
    },
    confirmTrackCancel() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
    },
    confirmTrackContinue() {
      event.preventDefault();
      var myModal = Modal.getOrCreateInstance(
        document.getElementById("modalConfirmation")
      );
      myModal.hide();
      if (this.toDelete == 1) {
        this.chapters.splice(this.chapterDelete, 1);
      } else if (this.toDelete == 2) {
        this.chapters[this.chapterDelete].parts.splice(this.partDelete, 1);
      } else if (this.toDelete == 3) {
        this.chapters[this.chapterDelete].parts[this.partDelete].tasks.splice(
          this.taskDelete,
          1
        );
      }
    },

    changeScreen() {
      this.screen = this.options.findIndex((option) => {
        return option.active;
      });
      if (this.screen === 1) {
        this.showResources = !this.showResources;
      }
    },
    loadResources(nullable) {
      if (this.themes.length === 0) {
        this.chapters.forEach((chapter) => {
          this.themes.push({
            title: chapter.title,
            id: chapter.id,
            resources: typeof solution_id === "number" ? [] : chapter.resources,
            aparts: chapter.solution,
          });
        });
        this.theme = this.themes;
      }
      if (!nullable) {
        this.showResources = !this.showResources;
      }
    },
    updateSyllabus(request) {
      this.$emit("updateInfo", request);
    },
    hoverIn(value) {
      this.hover = true;
      this.hoverId = value;
    },
    hoverOut() {
      this.hover = false;
    },
    closeResources() {
      this.showResources = false;
    },
  },
  watch: {
    chapters: function (val) {
      this.$emit("updateChaptersInfo", val, this.template.id);
    },
    projectNameContent: function (val) {
      this.$emit("updateProjectName", val);
    },
    taskManagerUrl: function (val) {
      this.taskManager = val;
    },
  },
  computed: {},
  mixins: [translationMixin],
};
</script>
<style>
.chosen {
  background-color: #ffffff;
  border: 2px solid#301889 !important;
  box-sizing: border-box;
  box-shadow: -1px 2px 4px 2px rgba(48, 24, 137, 0.2);
}

.ghost {
  opacity: 1;
  border: 2px solid#301889;
  box-sizing: border-box;
  box-shadow: -1px 2px 4px 2px rgba(48, 24, 137, 0.2);
}
</style>

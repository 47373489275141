<template>
  <div class="modal-general-mask">
    <div class="modal-general-wrapper">
      <div class="modal-import-template-container div-center">
        <div class="modal-import-template-header">
          <h1>{{ langFilter("header_title") }}</h1>
          <span @click="closeModal" class="modal-send-correction-close-btn"
            >x</span
          >
        </div>
        <div class="modal-import-template-body">
          <ul>
            <li>{{ langFilter("file_format") }} <strong> .docx</strong></li>
            <li>{{ langFilter("document_format") }}</li>
          </ul>
          <div class="d-flex flex-column ms-5 position-relative">
            <span>
              <strong>{{ langFilter("themes_title") + " " }}</strong
              >({{ langFilter("title_1") }})
            </span>
            <span>
              <strong>{{ langFilter("themes_description") + " " }}</strong
              >(Normal)
            </span>
            <span>
              <strong>{{ langFilter("sections_title") + " " }}</strong
              >({{ langFilter("title_2") }})
            </span>
            <span>
              <strong>{{ langFilter("questions_title") + " " }}</strong
              >({{ langFilter("title_3") }})
            </span>
            <span>
              <strong>{{ langFilter("question_description") + " " }}</strong
              >(Normal)
            </span>
            <span>
              <strong>{{ langFilter("question_prompt") + " " }}</strong
              >({{ langFilter("title_6") }})
            </span>
            <span
              @mouseover="showImage = true"
              @mouseout="showImage = false"
              class="modal-import-floating-span position-absolute"
              ><strong>{{ langFilter("example") }}</strong>
              <img
                class="ms-1 mb-3"
                :src="require('@/assets/img/projects/interrogation_mark.svg')"
                alt="Interrogation Icon"
              />
            </span>
          </div>
          <hr />
        </div>
        <div>
          <input
            type="file"
            ref="fileInput"
            style="display: none"
            @change="onFileChange"
          />
          <button
            @click="openFileInput"
            class="btn btn-md rounded-pill"
            style="background-color: #815bf0; color: #ffffff"
          >
            {{ langFilter("import") }}
          </button>
        </div>
      </div>

      <img
        v-if="showImage"
        :src="ejemploImg"
        alt="Ejemplo"
        class="example-image position-absolute"
      />
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import ejemploImg from "../../assets/img/projects/exampleImportTemplate.png";
import translations from "./translate/NewProjectsV2Component.json";

export default {
  props: ["lang", "selected_program_id", "space_id", "projectName"],
  data() {
    return {
      showImage: false,
      ejemploImg,
      traducciones: translations,
    };
  },
  methods: {
    closeModal() {
      this.$emit("toggle-modal");
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      const file = event.target.files[0];
      this.$emit("file-selected", file);
    },
  },
  mixins: [translationMixin],
};
</script>

<style lang="scss">
@import "../../../src/assets/css/modals/confirmation.css";
</style>
<template>
  <div class="confirmation-backdrop">
    <div
      :class="[
        { 'confirmation-modal-fixed': fixed, 'confirmation-container': !fixed },
        'modal-content',
      ]"
    >
      <button @click="closeConfirmation" class="confirmation-close-btn" />
      <div class="confirmation-body">
        <p class="confirmation-txt">
          {{ langFilter(body) }}
        </p>
        <p v-if="warning">
          <span class="confirmation-warning">
            {{ langFilter(warning) }}
          </span>
        </p>
      </div>
      <div class="confirmation-footer">
        <button
          @click="closeConfirmation"
          class="confirmation-btn confirmation-btn-cancel"
        >
          {{ this.langFilter(info ? "close" : "cancel") }}
        </button>
        <button v-if="!info" @click="confirm" class="confirmation-btn">
          {{ this.langFilter("continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["lang", "body", "fixed", "warning", "info"],
  data() {
    return {
      traducciones: [
        {
          name: "cancel",
          es: "Cancelar",
          en: "Cancel",
        },
        {
          name: "close",
          es: "Cerrar",
          en: "Close",
        },
        {
          name: "continue",
          es: "Continuar",
          en: "Continue",
        },
        {
          name: "noSave",
          es: "Se perderán los datos no guardados. ¿Deseas continuar?",
          en: "Unsaved data will be lost. Do you want to continue?",
        },
        {
          name: "deleteSure",
          es: "¿Estás seguro que lo quieres eliminar?",
          en: "Are you sure you want to delete?",
        },
      ],
    };
  },
  created() {},
  methods: {
    closeConfirmation() {
      this.$emit("closeConfirmation");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
  mixins: [translationMixin],
};
</script>
